<template>
    <div id="app">
        <div class="alert-logo">
            <img src="../assets/logo-vlechtmuseum.svg">
        </div>
        <div class="alertmessage">
            <p>U bent van harte welkom in ons museum om de geschiedenis van het vlechtambacht te beleven. De audiotour leidt u als een digitale gids door de monumentale Rijksrietvlechtschool. Zo wordt u uitlegd hoe Noordwolde uitgroeide tot het Vlechtdorp van Nederland.</p>
            <br/><p><a href="https://www.vlechtmuseum.nl">www.vlechtmuseum.nl</a></p>
        </div>
        <div class="footer">
            <hr>
            <div class="copy-right">&copy; Easycast Audio Tour</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Alert"
}
</script>

<style scoped>
.alertmessage {
    padding-top: 40px;
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    height: fit-content;
    min-height: 100%;
    padding-bottom: 140px;
    color: black;
}
.alertmessage a {
    color: #2c2d33;
}
.alert-logo {
    padding-top: 80px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}
.alert-logo img {
    width: 100%;
    height: auto;
}
</style>
