<template>
    <div id="app">
            <div class="container-home">
                <div class="container-header">Audiotour</div>
                <div class="container-logo">
                </div>
                <div class="container-title">
                </div>
                <div class="container-home-buttons">
                    <div class="btn-home-language" @click="showWelcome('nl')">
                        <div class="btn-home-icon"><img class="img-home-btn" src="../assets/buttons/plus-button.png"></div>
                        <div class="btn-home-label">Welkom</div>
                    </div>
                    <div class="btn-home-language" @click="showWelcome('de')">
                        <div class="btn-home-icon"><img class="img-home-btn" src="../assets/buttons/plus-button.png"></div>
                        <div class="btn-home-label">Wilkommen</div>
                    </div>
                    <div class="btn-home-language" @click="showWelcome('en')">
                        <div class="btn-home-icon"><img class="img-home-btn" src="../assets/buttons/plus-button.png"></div>
                        <div class="btn-home-label">Welcome</div>
                    </div>
                </div>
            </div>
        <div class="footer">
            <hr>
            <div class="copy-right">&copy; Easycast Audio Tour</div>
        </div>
    </div>
</template>

<script>
    // import configData from '../../public/config/app.json';
    import router from '../router';
    import axios from 'axios';

    export default {
        name: 'Home',
        components: {},
        data() {
            return {
                nlActive: false,
                deActive: false,
                enActive: false,
                frActive: false,
                xActive: false,
                languages: []
            }
        },
        methods: {
            showWelcome: function (langIn) {
                router.push('/welcome/' + langIn);
            },
            showIntro: function (langIn) {
                router.push('/intro/' + langIn);
            }
        },
        created() {
            axios.get('/config/app.json')
            .then((res) => {
                let configData = res.data;
                this.languages = configData.languages;
                this.languages.forEach((language) => {
                    if(language === 'nl') {
                        this.nlActive = true;
                    }
                    if(language === 'de') {
                        this.deActive = true;
                    }
                    if(language === 'en') {
                        this.enActive = true;
                    }
                    if(language === 'fr') {
                        this.frActive = true;
                    }
                    if(language === 'x') {
                        this.xActive = true;
                    }
                    if(this.languages.length === 1) {
                        let languageToUse = this.languages[0];
                        router.push('/groups/' + languageToUse);
                    }
                });
            })
            .catch((err) =>{ console.log('config error: ' + err) });
        }
    }
</script>

<style>
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    body {
        font-family: Arial, Helvetica, sans-serif;
        line-height: 1.4;
    }

    .container-home {
        margin-top: 5%;
        width: 100%;
        /*margin-left: auto;*/
        /*margin-right: auto;*/
        padding-bottom: 40px;
    }

    .container-header {
        width: 60%;
        /*max-width: 350px;*/
        font-family: 'Ubuntu', Helvetica, Arial, sans-serif;
        letter-spacing: 3px;
        font-variant-caps: all-small-caps;
        font-size: 2.2em;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    @media screen and (min-width: 700px) {
        .container-header {
            width: 60%;
            /*max-width: 350px;*/
            font-family: 'Ubuntu', Helvetica, Arial, sans-serif;
            font-weight: 500;
            letter-spacing: 5px;
            font-variant-caps: all-small-caps;
            font-size: 3.5em;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }
    }
    @media screen and (min-width: 450px) {
        .container-header {
            width: 60%;
            /*max-width: 350px;*/
            font-family: 'Ubuntu', Helvetica, Arial, sans-serif;
            font-weight: 500;
            letter-spacing: 5px;
            font-variant-caps: all-small-caps;
            font-size: 2.5em;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .container-logo {
        width: 35%;
        /*max-width: 300px;*/
        /*height: auto;*/
        margin-right: auto;
        margin-left: auto;
        margin-top: 10%;
        margin-bottom: 5%;
    }

    .img-logo {
        width: 100%;
    }

    .container-title {
        width: 55%;
        max-width: 640px;
        margin-left: auto;
        margin-right: auto;
    }

    .img-title {
        width: 100%;
    }

    .container-home-buttons {
        width: 35%;
        max-width: 250px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10%;
    }

    .btn-home-language {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 6%;
    }

    .btn-home-icon {
        width: 30%;
    }
    .img-home-btn {
        width: 100%;
    }
    .btn-home-label {
        width: 70%;
        /*margin-left: 10%;*/
        margin-top: -5px;
        font-family: 'Ubuntu', Helvetica, Arial, sans-serif;
        font-weight: 500;
        font-variant-caps: all-small-caps;
        font-size: 1.2em;
        letter-spacing: 2px;
    }
    .footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        padding-left: 0% !important;
        padding-right: 0% !important;
        color: black;
        text-align: center;
        /*background-color: #2e368b !important;*/
    }

    .copy-right {
        padding-top: 6px;
        padding-bottom: 6px;
    }
    @media screen and (min-width: 480px){
        .container-header {
            width: 60%;
            max-width: 480px;
            font-family: 'Ubuntu', Helvetica, Arial, sans-serif;
            font-weight: 500;
            font-variant-caps: all-small-caps;
            letter-spacing: 5px;
            font-size: 4em;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
        }
        .btn-home-label {
            width: 70%;
            /*margin-left: 10%;*/
            margin-top: -8px;
            font-family: 'Ubuntu', Helvetica, Arial, sans-serif;
            font-weight: 500;
            font-variant-caps: all-small-caps;
            font-size: 1.6em;
            letter-spacing: 2px;
        }
        .btn-label {
            width: 70%;
            margin-left: 10%;
            font-family: 'Ubuntu', Helvetica, Arial, sans-serif;
            font-weight: 500;
            font-variant-caps: all-small-caps;
            font-size: 2em;
        }
    }
</style>
