<template>
  <div id="app">
    <!-- <Header /> -->
    <router-view :key="$route.fullPath"/>
  </div>
</template>
<script>
export default {
  name: "app",
  components: {
  }
}
</script>
<style>
@font-face {
    font-face-name: Prompt;
    src: url("/assets/fonts/Prompt/Prompt-Regular.ttf");
}
@font-face {
    font-face-name: Prompt-medium;
    src: url("/assets/fonts/Prompt/Prompt-Medium.ttf");
}

body {
  background-color: #eab940;
}
#app {
  font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
  color: black;
  background-color: #eab940;
}
.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  z-index: 999;
  background-color: #eab940;
}
.header .home {
    position: absolute;
    padding: 5px;
    height: 70px;
    top: 5px;
    left: 5%;
}
.header .home img {
    height: 100% !important;
    width: auto !important;
}

.header .logo {
    position: fixed;
    right: 0;
    top: 0;
}
.header .logo img {
    width: 150px;
    height: auto;
}
  .header .sensors {
    position: absolute;
    padding: 5px;
    height: 60px;
    width: 100%;
    top: 10px;
    /* align-items: center; */
  }
  .header .sensors img {
    height: 45px;
    width: auto;
    margin-right: 0;
  }
  .header .buttonboard {
    position: absolute;
    right: 5%;
    top: 20px;
    width: auto;
    height: 45px;
  }
  .header .divider {
    width: 100%;
    position: absolute;
    top: 70px;
  }
  .header .divider hr {
    /* margin-top: 5px; */
    width: 90%;
  }
  .footer {
      background-color: #eab940;
  }
</style>
