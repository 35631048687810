import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import Intro from '../views/Intro'
import Instruction from '../views/Instruction'
import Welcome from '../views/Welcome'
import ObjectPage from '../views/ObjectPage'
import QRScanner from '../views/QRScanner'
import KeyPad from '../views/KeyPad'
import ButtonBoard from '../views/ButtonBoard'
import Groups from "@/views/Groups";
import GroupItems from "@/views/GroupItems";
import Alert from "@/views/Alert";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/welcome/:langId',
    name: 'welcome',
    component: Welcome,
    props: true
  },
  {
    path: '/intro/:langId',
    name: 'intro',
    component: Intro,
    props: true
  },
  {
    path: '/instruct/:langId',
    name: 'instruct',
    component: Instruction,
    props: true
  },
  {
    path: '/object/:langId/:groupId/:objectId',
    name: 'objectgroup',
    component: ObjectPage,
    props: true
  },
  {
    path: '/object/:langId/:objectId',
    name: 'object',
    component: ObjectPage,
    props: true
  },
  {
    path: '/qr/:langId',
    name: 'qr',
    component: QRScanner,
    props: true
  },
  {
    path: '/key/:langId',
    name: 'key',
    component: KeyPad,
    props: true
  },
  {
    path: '/buttons/:langId',
    name: 'buttons',
    component: ButtonBoard,
    props: true
  },
  {
    path: '/groups/:langId',
    name: 'groups',
    component: Groups,
    props: true
  },
  {
    path: '/group/:groupId/:langId',
    name: 'group',
    component: GroupItems,
    props: true
  },
  {
    path: '/message',
    name: 'message',
    component: Alert,
    meta: {
      accessAll: true
    }
  }

]

const router = new VueRouter({
  mode: 'hash',
  routes,
  scrollBehaviour() {
    return { x:0, y:0}
  }
});
/**
router.beforeEach((to, from, next) => {
  if(!to.meta.accessAll) {
    let ipAddressVisitor = '0.0.0.0';
    let ipAddressLocation = '86.88.227.210';
    // let ipJorwert = '82.73.56.141';
    let ipJorwert = '84.24.226.97'
    let ipZwart = '5.104.114.208';
    let ipNieuwenhoven = '82.73.63.23';
    fetch("https://api.ipify.org?format=json")
        .then(async response => {
          const data = await response.json();
          // check for error response
          if (!response.ok) {
            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
          }
          ipAddressVisitor = data.ip;
          if(ipAddressVisitor === ipAddressLocation || ipAddressVisitor === ipJorwert || ipAddressVisitor === ipZwart || ipAddressVisitor === ipNieuwenhoven) {
            next();
          } else {
            next({
              path: '/message',
              replace: true
            });
            // return false;
          }
        })
        .catch(error => {
          this.errorMessage = error;
          console.error("There was an error!", error);
          next();
        });
  } else {
    // console.log('access not restricted');
    next();
  }
});
 **/
export default router
