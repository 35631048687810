<template>
  <div id="app">
      <div class="header">
          <div class="home" @click="showGroups"><img src="../assets/buttons/header_home.svg" alt="home"></div>
          <div class="sensors">
<!--              <img class="btn-keypad" v-if="keyActive" src="../assets/buttons/header_keypad.svg" alt="Keypad" @click="showKey">-->
<!--              <img v-if="qrActive" src="/assets/buttons/qr-button-diap.png" alt="QR Scanner" @click="showQR">-->
          </div>
          <div class="header-labels">
              <div class="header-label-home">{{ labelHome }}</div>
<!--              <div class="header-label-list">{{ labelList }}</div>-->
              <div class="header-label-keypad">{{ labelKeypad }}</div>
          </div>
      </div>
      <div v-if="bbWide && bbNr" class="bb-container">
          <div class="key-wide-left" v-bind:class="{'none': item.tagnr % 2 === 0, 'darker': item.tagnr % 2 !== 0}" v-bind:key="item.id" v-for="item in itemList" @click="getObject(item.id)">
              <div class="image-container">
                  <div class="image-button"><img :src="item.image"></div>
                  <div class="key-play"><img src="../assets/buttons/play.png"></div>
              </div>
<!--              <div class="image-button"><img :src="item.image"></div>-->
<!--              <div class="key-play"><img src="../assets/buttons/play.png"></div>-->
              <div class="key-label"><div class="nr" v-if="bbNr">{{ item.label }}</div><div class="title">{{ item.title }}</div></div>

          </div>
      </div>
      <div v-if="bbWide && !bbNr" class="bb-container">
          <div class="key-wide" v-bind:key="item.id" v-for="item in itemList" @click="getObject(item.id)">
              <div><span class="nr" v-if="bbNr">{{ item.label }}: </span>{{ item.title }}</div>

          </div>
      </div>
    <div v-if="!bbWide" class="bb-container">
      <div class="key" v-bind:key="item.id" v-for="item in itemList" @click="getObject(item.id)">
        <span>{{ item.label }}</span>
      </div>
    </div>
    <div class="footer">
      <hr>
      <div class="copy-right">&copy; Easycast Audio Tour</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import router from '../router';

export default {
name: "ButtonBoard",
  props: ['langId'],
  data() {
    return {
      apiUrl: null,
      apiKey: null,
      apiCollection: null,
      qrActive: false,
      keyActive: false,
        browseStart: false,
        browseActive: false,
        backToStartActive: false,
      bbWide: false,
      bbNr: false,
        languageX: '',
        labelHome: '',
        labelKeypad: '',
        labelList: '',
      itemList: []
    }
  },
  methods: {
    showHome: function() {
      router.push('/');
    },
    showWelcome: function() {
      router.push('/welcome/' + this.langId);
    },
      showIntro: function() {
          router.push('/intro/' + this.langId);
      },
      showGroups: function() {
          router.push('/groups/' + this.langId);
      },
      showStart: function() {
          if(this.browseStart && this.browseActive) {
              // open first subject in audiotour
              axios.get(this.apiUrl + 'items?tags=audio-01&collection=' + this.apiCollection + '&key=' + this.apiKey)
                  .then((res) => {
                          let objectId = res.data[0].id;
                          router.push('/object/'+this.langId+'/'+objectId);
                      }
                  )
                  .catch(err => console.log(err));
          } else {
              router.push('/intro/' + this.langId);
          }
      },
    showQR: function() {
      router.push('/qr/'+this.langId);
    },
    showKey: function() {
      router.push('/key/'+this.langId);
    },
    getObject: function(idIn) {
      router.push('/object/'+this.langId+'/'+idIn);
    },
    sortItems: function(itemsToSort) {
        return itemsToSort.sort(function(a, b) {
            return a.id - b.id;
        });
    }
  },
  created() {
    axios.get('/config/app.json')
        .then((res) => {
          let configData = res.data;
          this.qrActive = configData.qrscanner;
          this.keyActive = configData.keypad;
          this.browseActive = configData.browse;
          this.backToStartActive = configData.startbutton;
          this.browseStart = configData.browse_start;
          this.bbWide = configData.bbwide;
          this.bbNr = configData.bbnr;
          this.apiUrl = configData.api;
          this.apiKey = configData.api_key;
          this.apiCollection = configData.api_collection;
          this.languageX = configData.language_x;
          //210324 - because of introduction new object-type: audio-group query has to include item_type:18 audiotour-item
            if(this.langId === 'nl') {
                this.labelHome = configData.header.nl.label_home;
                this.labelList = configData.header.nl.label_list;
                this.labelKeypad = configData.header.nl.label_keypad;
            }
            if(this.langId === 'de') {
                this.labelHome = configData.header.de.label_home;
                this.labelList = configData.header.de.label_list;
                this.labelKeypad = configData.header.de.label_keypad;
            }
            if(this.langId === 'en') {
                this.labelHome = configData.header.en.label_home;
                this.labelList = configData.header.en.label_list;
                this.labelKeypad = configData.header.en.label_keypad;
            }
            if(this.langId === 'fr') {
                this.labelHome = configData.header.fr.label_home;
                this.labelList = configData.header.fr.label_list;
                this.labelKeypad = configData.header.fr.label_keypad;
            }
            if(this.langId === 'x') {
                this.labelHome = configData.header.x.label_home;
                this.labelList = configData.header.x.label_list;
                this.labelKeypad = configData.header.x.label_keypad;
            }
          axios.get(this.apiUrl + 'items?collection=' + this.apiCollection + '&item_type=18&key=' + this.apiKey)
          .then((itemsResult) => {
            if(itemsResult) {
              itemsResult.data.forEach((item) => {
                item.element_texts.forEach((text_element) => {
                  if (text_element.element.name === 'Titel_' + this.langId) {
                    if(text_element.text !== '') {
                        item.tags.forEach((tagFound) => {
                            let tagArray = tagFound.name.split('-');
                            if(tagArray[0] === 'audio') {
                                let itemToPush = {};
                                axios.get(this.apiUrl + 'files?item=' + item.id + '&key=' + this.apiKey)
                                    .then((filesResult) => {
                                        if(filesResult) {
                                            filesResult.data.forEach((fileFound) => {
                                                if (fileFound.mime_type === 'image/jpeg' || fileFound.mime_type === 'image/png') {
                                                    itemToPush.image = fileFound.file_urls.thumbnail;
                                                }
                                            });
                                        }
                                        // this.itemList.push(itemToPush);
                                        let tagNr = parseInt(tagArray[1]);
                                        itemToPush.id = item.id;
                                        itemToPush.tagnr = tagNr;
                                        itemToPush.label = tagArray[1];
                                        itemToPush.title = text_element.text;
                                        this.itemList.push(itemToPush);
                                        this.itemList = this.sortItems(this.itemList);
                                    })
                                    .catch((filesErr) => {
                                        console.log('error files: ' + filesErr);
                                    })

                                // console.log('audio: ' + parseInt(tagArray[1]) + ': ' + text_element.text);
                            }
                        });
                    }
                  }
                });
              });
            // console.log('itemList: ' + JSON.stringify(this.itemList));
            } else {
              console.log('nothing found!');
            }
          })
          .catch((err) => { console.log('created(): error getting itemlist: ' + err) });
        })
        .catch((err) => { console.log('created(): error config: ' + err) });
  }
}
</script>

<style scoped>
.bb-container {
  margin-top: 122px;
  margin-left: auto;
  margin-right: auto;
    margin-bottom: 80px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 600px;
}
.bb-container .key {
  display:inline-flex;
  flex: 0 0 30%;
  /* padding: 10px; */
  font-family: 'Ubuntu Medium', sans-serif;
  font-size: 25px;
  font-weight: bold;
  /* width: 33%; */
  height: 60px;
  background-color: white;
  color: black;
  text-align: center;
  vertical-align: middle;
  margin: 5px;
  border-radius: 5px;
}
.bb-container .key-wide {
  display:inline-flex;
  flex: 0 0 100%;
  /* padding: 10px; */
    font-family: 'Ubuntu Medium', sans-serif;
  font-size: 25px;
  font-weight: bold;
  /* width: 33%; */
  height: 60px;
  background-color: white;
  color: black;
  text-align: center;
  vertical-align: middle;
  margin: 5px;
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
}
.bb-container .key-wide-left {
    display:inline-flex;
    width: 100%;
    /*flex: 0 0 100%;*/
    margin-bottom: 1px;
    font-family: 'Ubuntu Medium', sans-serif;
    font-size: 1.3em;
    font-weight: bold;
    height: 70px;
    color: black;
    text-align: left;
}
.bb-container .key-wide-left .image-container {
    position: relative;
    width: 25%;
    height: 100%;
    max-width: 80px;
    margin-bottom: auto;
    margin-top: auto;
    /*background-color: #584C4C;*/
    text-align: center;
}
.bb-container .key-wide-left .image-container .key-play {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 80px;
    margin-bottom: auto;
    margin-top: auto;
    background-color: transparent;
    text-align: center;
}
.bb-container .key-wide-left .image-container .key-play img {
    width: 30%;
    height: 100%;
    object-fit: contain;
}
.bb-container .key-wide-left .image-container .image-button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 80px;
    margin-bottom: auto;
    margin-top: auto;
    /*background-color: #584C4C;*/
    /*text-align: center;*/
}
.bb-container .key-wide-left .image-container .image-button img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.bb-container .key-wide-left .key-play {
    position: relative;
    width: 25%;
    height: 100%;
    max-width: 80px;
    margin-bottom: auto;
    margin-top: auto;
    background-color: #584C4C;
    text-align: center;
}
.bb-container .key-wide-left .key-play img {
    width: 30%;
    height: 100%;
    object-fit: contain;
}
.bb-container .key-wide-left .image-button {
    width: 25%;
    height: 100%;
    max-width: 80px;
    margin-bottom: auto;
    margin-top: auto;
    /*background-color: #584C4C;*/
    /*text-align: center;*/
}

.bb-container .key-wide-left .image-button img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.bb-container .key-wide-left .key-label {
    display: flex;
    align-items: center;
    width: 75%;
    /*height: 100%;*/
    padding-left: 2%;
}

.bb-container .darker {
    background-color: #00000030;
}
.bb-container .key span {
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 15px;
}
.bb-container .key-wide div {
    width: 100%;
    height: 100%;
    text-align: center;
    padding-top: 15px;
}
.bb-container .key-wide div span.nr {
    width: 30%;
    /*height: 100%;*/
    text-align: center;
    /*padding-top: 15px;*/
}
.bb-container .key-wide-left .key-label .nr {
    width: 15%;
    /*height: 100%;*/
    text-align: left;
    /*padding-top: 15px;*/
}
.bb-container .key-wide-left .key-label .title {
    width: 85%;
    /*height: 100%;*/
    text-align: left;
    /*padding-top: 15px;*/
}
.header .sensors {
    position: absolute;
    padding: 5px;
    height: 70px;
    width: 100%;
    top: 0px;
    text-align: center;
}
.header .sensors img {
    height: 50px;
    width: auto;
    /*margin-right: 30px;*/
}
.header .sensors img.btn-keypad {
    height: 50px;
    width: auto;
    margin-right: 0;
    margin-top: 16px;
}
.header .flag {
  position: absolute;
  right: 5%;
  top: 5px;
  width: auto;
  height: 60px;
}
.header-labels {
    position: absolute;
    font-size: 16px;
    /*position: absolute;*/
    top: 80px;
    left: 0;
    width: 100%;
    height: 22px;
    background-color: black;
    color: white;
    z-index: 999;
}
.header-labels .header-label-home {
    position: absolute;
    top: 0;
    left: 4%;
    width: 75px;
    text-align: center;
    /*margin-left: 4%;*/
}
.header-labels .header-label-keypad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
}
.header-labels .header-label-list {
    position: absolute;
    top: 0;
    right: 5%;
    width: 60px;
    text-align: center;
    /*margin-left: 4%;*/
}

</style>
