<template>
  <div id="app">
    <div class="header">
      <div class="home-back" @click="showGroup"><img src="/assets/buttons/back.png" alt="home"></div>
        <div class="logo"><img src="/assets/logo_mensinge.png"></div>
<!--      <div class="sensors">-->
<!--        <img class="btn-keypad" v-if="keyActive" src="../assets/buttons/header_keypad.svg" alt="Keypad" @click="showKey">-->
<!--        <img v-if="qrActive" src="/assets/buttons/qr-button-diap.png" alt="QR Scanner" @click="showQR">-->
<!--        <img v-if="groupsActive" src="/assets/buttons/list-button-diap.png" alt="Group Buttons" @click="showGroups">-->
<!--      </div>-->
<!--      <img class="buttonboard" v-if="bbActive" src="../assets/buttons/header_list.svg" alt="Subject Buttons" @click="showBB">-->
<!--        <div class="header-labels">-->
<!--            <div class="header-label-home">{{ labelHome }}</div>-->
<!--            <div class="header-label-list">{{ labelList }}</div>-->
<!--            <div class="header-label-keypad">{{ labelKeypad }}</div>-->
<!--        </div>-->
    </div>
    <div class="object-container">
      <ObjectFiles v-bind:files="files" v-bind:lang="langId" v-bind:browserOs="browserOs" v-bind:browserName="browserName"/>
        <div class="title-container">
<!--            <div class="object-tag">{{ tagCurrent }}</div>-->
            <div class="object-title">{{ groupTitle.split(':')[1] }}: <br/> {{ objectTitle }}</div>
        </div>
      <div v-html="objectDescription" class="object-description"></div>
    </div>
    <div class="footer">
      <hr>
      <div class="copy-right">&copy; Easycast Audio Tour</div>
    </div>
  </div>
</template>

<script>
import ObjectFiles from '../components/ObjectFiles';
import axios from 'axios';
import router from '../router';
// import configData from '../../public/config/app.json';
export default {
  name: 'ObjectPage',
  components: {
    ObjectFiles
  },
  props: ['langId', 'groupId', 'objectId'],
  data() {
    return {
      apiKey: null,
      apiUrl: null,
      apiCollection: null,
      files: [],
      objectData: [],
      browserOs: null,
      browserName: null,
      keyActive: false,
      qrActive: false,
      bbActive: false,
      groupsActive: false,
      objectTitle: '',
      objectDescription: '',
      browseActive: false,
        browseStart: false,
      browseMax: 0,
      browseCurrent: 0,
        tagCurrent: '',
      browseNext: false,
      browsePrevious: false,
      labelNext: 'Volgende',
      labelPrevious: 'Vorige',
      groupTitle: '',
        languageX: '',
        labelHome: '',
        labelKeypad: '',
        labelList: ''
    }
  },
  methods: {
      showHome: function() {
          router.push('/');
      },
      showGroup: function() {
          router.push('/group/' + this.groupId + '/' + this.langId);
      },
    showWelcome: function() {
      router.push('/welcome/'+this.langId);
    },
    showKey: function() {
      router.push('/key/'+this.langId);
    },
    // showHome: function() {
    //   router.push('/intro/'+this.langId);
    // },
    showQR: function() {
      router.push('/qr/'+this.langId);
    },
    showBB: function() {
      router.push('/buttons/'+this.langId);
    },
    showGroups: function() {
      router.push('/groups/'+this.langId);
    },
    getGroupObjects: function(tagIn) {
      router.push('/group/' + tagIn + '/' + this.langId);
    },
    showNext: function () {
      let tagnrNext = this.browseCurrent + 1;
      let tagStringNext = '';
      if(tagnrNext < 10) {
        tagStringNext = 'audio-0' + tagnrNext;
      } else {
        tagStringNext = 'audio-' + tagnrNext;
      }
      axios.get(this.apiUrl + 'items?tags='+tagStringNext+'&collection=' + this.apiCollection + '&key=' + this.apiKey)
          .then((res) => {
                let objectId = res.data[0].id;
                router.push('/object/'+this.langId+'/'+objectId);
              }
          )
          .catch(err => console.log(err));
    },
    showPrevious: function () {
      let tagnrPrev = this.browseCurrent - 1;
      let tagStringPrev = '';
      if(tagnrPrev < 10) {
        tagStringPrev = 'audio-0' + tagnrPrev;
      } else {
        tagStringPrev = 'audio-' + tagnrPrev;
      }
      axios.get(this.apiUrl + 'items?tags='+tagStringPrev+'&collection=' + this.apiCollection + '&key=' + this.apiKey)
          .then((res) => {
                let objectId = res.data[0].id;
                router.push('/object/'+this.langId+'/'+objectId);
              }
          )
          .catch(err => console.log(err));
    },
      showStart: function() {
          if(this.browseStart && this.browseActive) {
              // open first subject in audiotour
              axios.get(this.apiUrl + 'items?tags=audio-01&collection=' + this.apiCollection + '&key=' + this.apiKey)
                  .then((res) => {
                          let objectId = res.data[0].id;
                          router.push('/object/'+this.langId+'/'+objectId);
                      }
                  )
                  .catch(err => console.log(err));
          } else {
              router.push('/intro/' + this.langId);
          }
      }
  },
  created() {
      console.log('objectpage started...');
    axios.get('/config/app.json')
    .then((res) => {
      let configData = res.data;
      this.apiUrl = configData.api;
      this.apiKey = configData.api_key;
      this.apiCollection = configData.api_collection;
      const { detect } = require('detect-browser');
      const browser = detect();
      this.browserOs = browser.os;
      this.browserName = browser.name;
      this.keyActive = configData.keypad;
      this.qrActive = configData.qrscanner;
      this.bbActive = configData.buttonboard;
      this.groupsActive = configData.groups;
        this.browseActive = configData.browse;
        this.browseStart = configData.browse_start;
      this.browseMax = configData.browse_max;
      this.languageX = configData.language_x;
      // labels volgende / vorige knop
      if(this.langId === 'nl') {
        if(configData.label_button_next.nl !== '') {
          this.labelNext = configData.label_button_next.nl;
        } else {
          this.labelNext = '>';
        }
        if(configData.label_button_previous.nl !== '') {
          this.labelPrevious = configData.label_button_previous.nl;
        } else {
          this.labelPrevious = '<';
        }
      }
      if(this.langId === 'de') {
        if(configData.label_button_next.de !== '') {
          this.labelNext = configData.label_button_next.de;
        } else {
          this.labelNext = '>';
        }
        if(configData.label_button_previous.de !== '') {
          this.labelPrevious = configData.label_button_previous.de;
        } else {
          this.labelPrevious = '<';
        }
      }
      if(this.langId === 'en') {
        if(configData.label_button_next.en !== '') {
          this.labelNext = configData.label_button_next.en;
        } else {
          this.labelNext = '>';
        }
        if(configData.label_button_previous.en !== '') {
          this.labelPrevious = configData.label_button_previous.en;
        } else {
          this.labelPrevious = '<';
        }
      }
      if(this.langId === 'fr') {
        if(configData.label_button_next.fr !== '') {
          this.labelNext = configData.label_button_next.fr;
        } else {
          this.labelNext = '>';
        }
        if(configData.label_button_previous.fr !== '') {
          this.labelPrevious = configData.label_button_previous.fr;
        } else {
          this.labelPrevious = '<';
        }
      }
      axios.get(this.apiUrl + 'files?item='+this.objectId+'&key=' + this.apiKey)
              .then((res) => {
                  this.files = [];
                let filesFromAPI = res.data;
                // console.log('lang' + this.langId);
                  filesFromAPI.forEach((fileFound) => {
                      if(fileFound.mime_type.slice(0,6) === "image/") {
                          this.files.push(fileFound);
                      }
                      if(fileFound.mime_type === "audio/mpeg") {
                          fileFound.element_texts.forEach((elementText) => {
                              // console.log('gevonden: ' + elementText.text);
                              if(elementText.element.name === 'Title') {
                                  if (elementText.text === 'audio-' + this.langId) {
                                      this.files.push(fileFound);
                                      // console.log('toegevoegd: ' + elementText.text);
                                  }
                              }
                          });
                      }
                  });
                  // console.log('aantal files naar fileItems: ' + this.files.length);
              })
              .catch(err => console.log(err));
      // if applicable: name group
      axios.get(this.apiUrl + 'items?item_type=19&collection=' + this.apiCollection + '&key=' + this.apiKey)
      .then((tagResult) => {
        if(tagResult && tagResult.data.length > 0) {
          tagResult.data.forEach((groupRecord) => {
            groupRecord.element_texts.forEach((text_element) => {
              if(text_element.element.name === 'Tag naam' && text_element.text === this.groupId) {
                groupRecord.element_texts.forEach((text_element) => {
                  if(text_element.element.name === 'Titel_' + this.langId) {
                    this.groupTitle = text_element.text;
                    console.log('group title: ' + this.groupTitle);
                  }
                });
              }
            });
          });
          tagResult.data.element_texts.forEach((text_element) => {
            if(text_element.element.name === 'Titel_' + this.langId) {
              this.groupTitle = text_element.text;
              console.log('group title: ' + this.groupTitle);
            }
          })
        }
      })
      .catch((groupError) => {
        console.log('Group title error: ' + groupError);
      });
      // title, text and tags
        if(this.langId === 'nl') {
            this.labelHome = configData.header.nl.label_home;
            this.labelList = configData.header.nl.label_list;
            this.labelKeypad = configData.header.nl.label_keypad;
        }
        if(this.langId === 'de') {
            this.labelHome = configData.header.de.label_home;
            this.labelList = configData.header.de.label_list;
            this.labelKeypad = configData.header.de.label_keypad;
        }
        if(this.langId === 'en') {
            this.labelHome = configData.header.en.label_home;
            this.labelList = configData.header.en.label_list;
            this.labelKeypad = configData.header.en.label_keypad;
        }
        if(this.langId === 'fr') {
            this.labelHome = configData.header.fr.label_home;
            this.labelList = configData.header.fr.label_list;
            this.labelKeypad = configData.header.fr.label_keypad;
        }
        if(this.langId === 'x') {
            this.labelHome = configData.header.x.label_home;
            this.labelList = configData.header.x.label_list;
            this.labelKeypad = configData.header.x.label_keypad;
        }
      axios.get(this.apiUrl+ 'items/'+this.objectId+'?key=' + this.apiKey)
              .then((resObject) => {
                this.objectData = resObject.data;
                this.objectData.element_texts.forEach((text_element) => {
                  if(text_element.element.name === 'Titel_' + this.langId){
                    this.objectTitle = text_element.text;
                  }
                  if(text_element.element.name === 'Omschrijving_' + this.langId){
                    this.objectDescription = text_element.text;
                  }
                  this.objectData.tags.forEach((tagFound) => {
                    if(tagFound.name.substr(0,6) === 'audio-') {
                      this.tagCurrent = tagFound.name.split('-')[1];
                      this.browseCurrent = parseInt(this.tagCurrent);
                      if(this.browseCurrent > 1 && this.browseCurrent <= this.browseMax) {
                        this.browsePrevious = true;
                      }
                      if(this.browseCurrent < this.browseMax) {
                        this.browseNext = true;
                      }
                    }
                  });
                });
              })
              .catch(errObject => console.log(errObject));
    })
    .catch((err) => { console.log('config error: ' + err) });
  },
  beforeDestroy() {
    this.files=null;
    delete this.files;
  }
}
</script>

<style scoped>
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  body {
    font-family: Prompt-medium, Arial, sans-serif;
    color: white;
    line-height: 1.4;
    background-color: black;
  }
  .header .home-back {
      position: absolute;
      padding: 0px;
      height: 80px;
      width: 75px;
      top: 10px !important;
      left: 4%;
      z-index: 999;
  }
  .header .home-back img {
      height: 55px;
      width: auto;
  }
  .header .sensors {
      position: absolute;
      padding: 5px;
      height: 70px;
      width: 100%;
      top: 0px;
      text-align: center;
  }
  .header .sensors img {
      height: 50px;
      width: auto;
      /*margin-right: 30px;*/
  }
  .header .sensors img.btn-keypad {
      height: 50px;
      width: auto;
      margin-right: 0;
      margin-top: 16px;
  }
  .header .buttonboard {
      position: absolute;
      right: 3%;
      top: 10px;
      width: auto;
      height: 70px;
  }
  .header-labels {
      position: relative;
      font-size: 16px;
      /*position: absolute;*/
      top: 80px;
      left: 0;
      width: 100%;
      height: 22px;
      background-color: black;
      color: white;
  }
  .header-labels .header-label-home {
      position: absolute;
      top: 0;
      left: 4%;
      width: 75px;
      text-align: center;
      /*margin-left: 4%;*/
  }
  .header-labels .header-label-keypad {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      text-align: center;
  }
  .header-labels .header-label-list {
      position: absolute;
      top: 0;
      right: 5%;
      width: 60px;
      text-align: center;
      /*margin-left: 4%;*/
  }

  .btn {
    display: inline-block;
    border: none;
    background: #555;
    color: #fff;
    padding: 7px 20px;
    cursor: pointer;
  }

  .btn:hover {
    background: #666;
  }

  .object-container {
    padding-top: 122px;
    width: 100%;
    height: fit-content;
    min-height: 100%;
    padding-bottom: 210px;
    color: black;
      /*background-color: #262262;*/
  }
  .group-title {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-size: 2em;
  }
  .object-container-browse {
    padding-top: 120px;
    width: 100%;
    height: fit-content;
    min-height: 100%;
    padding-bottom: 140px;
    color: white;
  }

  .title-container {
      position: fixed;
      display: flex;
      align-items: center;
      /*bottom: 135px;*/
      top: 80px;
      width: 100%;
      height: 55px;
      /*background-color: #d2a62fee;*/
      background-color: #eab940;
  }

  .object-title {
    font-family: 'Prompt-medium', Arial, sans-serif;
    /*font-variant-caps: all-small-caps;*/
    font-size: 1.3em;
      margin-left: 10%;
    /*left: 30%;*/
    color: black;
      font-weight: 700;
  }

  .object-tag {
    width: 30%;
    font-family: Prompt-medium, Arial, sans-serif;
    font-variant-caps: all-small-caps;
    font-size: 1.8em;
    text-align: center;
      /*text-shadow: 1px 1px #CCCCCC;*/
      color: white;
      /*font-weight: 500;*/
  }

  .object-description {
      font-family: 'Prompt-medium', Arial, sans-serif;
      font-size: 1.2em;
    width: 80%;
      margin-top: 1em;
    margin-left: 10%;
    margin-right: 10%;
  }
  .object-browse {
    display: flex;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
    margin-top: 5px;
    background-color: #444444;
  }
  .object-next-container {
    padding: 5px;
    width: 50%;
  }
  .object-next {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    background-color: #666666;
  }
  .object-prev-container {
    padding: 5px;
    width: 50%;
  }
  .object-prev {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    background-color: #666666;
  }
  .stream-container {
    width: 90%;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
  .stream-container iframe {
    width: 100%;
    height: 200px;
  }
  .footer {
      position: fixed;
      bottom: 0;
      width: 100%;
      padding-left: 0% !important;
      padding-right: 0% !important;
      color: black;
      text-align: center;
      background-color: #eab940;
      /*background-color: #2e368b !important;*/
  }
  .copy-right {
    padding-top: 6px;
    padding-bottom: 6px;
  }

</style>
