<template>
  <div id="app">
    <div class="header">
        <div class="logo"><img src="/assets/logo_mensinge.png"></div>
<!--      <div class="sensors"><center>-->
<!--        <img v-if="keyActive" src="/assets/buttons/key-button-diap.png" alt="Keypad" @click="showKey">-->
<!--        <img v-if="qrActive" src="/assets/buttons/qr-button-diap.png" alt="QR Scanner" @click="showQR">-->
<!--        <img v-if="groupsActive" src="/assets/buttons/list-button-diap.png" alt="Group Buttons" @click="showGroups">-->
<!--      </center></div>-->
<!--        <img v-if="langId !== 'x'" class="flag" :src="'/assets/buttons/flags/'+langId+'_small.jpg'" @click="showHome">-->
<!--        <img v-if="langId === 'x'" class="flag" :src="'/assets/buttons/flags/'+languageX+'_small.jpg'" @click="showHome">-->
      <div class="home"><img src="/assets/buttons/mensinge_home.png" alt="home" @click="showGroups"></div>
<!--      <div class="divider"><center><hr></center></div>-->
    </div>
    <div class="title-group">{{ groupTitle }}</div>
<!--      <div v-if="bbWide && bbNr" class="buttons-container">-->
<!--          <div class="key-wide-left" v-bind:key="item.id" v-for="item in itemListSorted" @click="getObject(item.id)">-->
<!--            <img v-if="buttonImage" :src="item.image">-->
<!--            <div><span class="nr" v-if="bbNr">{{ item.label }}: </span>{{ item.title }}</div>-->

<!--          </div>-->
<!--      </div>-->
<!--      <div v-if="bbWide && !bbNr" class="buttons-container">-->
<!--          <div class="key-wide" v-bind:key="item.id" v-for="item in itemListSorted" @click="getObject(item.id)">-->
<!--              <img v-if="buttonImage" :src="item.image">-->
<!--              <div><span class="nr" v-if="bbNr">{{ item.label }}: </span>{{ item.title }}</div>-->

<!--          </div>-->
<!--      </div>-->
      <div class="buttons-container">
          <div class="key-wide" v-bind:key="item.id" v-for="item in itemListSorted" @click="getObject(item.id)">
              <img v-if="buttonImage" :src="item.image">
              <div><span class="nr" v-if="bbNr">{{ item.label }}: </span>{{ item.title }}</div>

          </div>
      </div>
<!--    <div v-if="!bbWide" class="buttons-container">-->
<!--      <div class="key" v-bind:key="item.id" v-for="item in itemListSorted" @click="getObject(item.id)">-->
<!--        <span>{{ item.label }}</span>-->
<!--      </div>-->
<!--    </div>-->
    <div class="footer">
      <hr>
      <div class="copy-right">&copy; Easycast Audio Tour</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import router from '../router';

export default {
name: "ButtonBoard",
  props: ['langId', 'groupId'],
  data() {
    return {
      apiUrl: null,
      apiKey: null,
      apiCollection: null,
      qrActive: false,
      keyActive: false,
        browseStart: false,
        browseActive: false,
        backToStartActive: false,
      groupsActive: false,
      groupTitle: '',
      bbWide: false,
      bbNr: false,
      buttonImage: false,
        languageX: '',
      itemList: []
    }
  },
  methods: {
    showHome: function() {
      router.push('/');
    },
    showWelcome: function() {
      router.push('/welcome/' + this.langId);
    },
    showIntro: function() {
      router.push('/intro/' + this.langId);
    },
    showQR: function() {
      router.push('/qr/'+this.langId);
    },
    showKey: function() {
      router.push('/key/'+this.langId);
    },
    showGroups: function() {
      router.push('/groups/'+this.langId);
    },
    getObject: function(idIn) {
      router.push('/object/'+this.langId+'/'+this.groupId+'/'+idIn);
    }
  },
    computed: {
        itemListSorted() {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            return this.itemList.sort((a, b) => {
                return a.id - b.id;
            });
        }
    },
  created() {
    axios.get('/config/app.json')
        .then((res) => {
          let configData = res.data;
          this.qrActive = configData.qrscanner;
          this.keyActive = configData.keypad;
          this.browseActive = configData.browse;
          this.backToStartActive = configData.startbutton;
          this.browseStart = configData.browse_start;
          this.bbWide = configData.bbwide;
          this.bbNr = configData.bbnr;
          this.groupsActive = configData.groups;
          this.apiUrl = configData.api;
          this.apiKey = configData.api_key;
          this.apiCollection = configData.api_collection;
          this.buttonImage = configData.buttonimage;
          this.languageX = configData.language_x;

          axios.get(this.apiUrl + 'items?collection=' + this.apiCollection + '&item_type=19&key=' + this.apiKey)
          .then((groupsResult) => {
            if(groupsResult) {
              groupsResult.data.forEach((group) => {
                group.element_texts.forEach((groupElementText) => {
                  if(groupElementText.element.name === 'Tag naam' && groupElementText.text === this.groupId) {
                    group.element_texts.forEach((groupElementTitle) => {
                      if(groupElementTitle.element.name === 'Titel_' + this.langId) {
                        this.groupTitle = groupElementTitle.text.split(':')[1];
                      }
                    });
                  }
                });
              });
            }
          })
          .catch((groupsError) => {
            console.log('onCreate: groups error' + groupsError);
          });

          //210324 - because of introduction new object-type: audio-group query has to include item_type:18 audiotour-item
          axios.get(this.apiUrl + 'items?collection=' + this.apiCollection + '&item_type=18&tags=' + this.groupId + '&key=' + this.apiKey)
          .then((itemsResult) => {
            if(itemsResult) {
              itemsResult.data.forEach((item) => {
                item.element_texts.forEach((text_element) => {
                  if (text_element.element.name === 'Titel_' + this.langId) {
                    if(text_element.text !== '') {
                        item.tags.forEach((tagFound) => {
                            let tagArray = tagFound.name.split('-');
                            if(tagArray[0] === 'audio') {
                                // console.log('audio: ' + text_element.text);
                              let itemToPush = {};
                                // check for files - thumbnail
                              axios.get(this.apiUrl + 'files?item=' + item.id + '&key=' + this.apiKey)
                              .then((filesResult) => {
                                if(filesResult) {
                                  filesResult.data.forEach((fileFound) => {
                                    if (fileFound.mime_type === 'image/jpeg') {
                                      itemToPush.image = fileFound.file_urls.thumbnail;
                                    }
                                  });
                                }
                                itemToPush.id = item.id;
                                itemToPush.label = tagArray[1];
                                itemToPush.title = text_element.text;
                                this.itemList.push(itemToPush);
                              })
                              .catch((filesErr) => {
                                console.log('error files: ' + filesErr);
                              })
                            }
                        });
                    }
                  }
                });
              });
            console.log('itemList: ' + JSON.stringify(this.itemList));
            } else {
              console.log('nothing found!');
            }
          })
          .catch((err) => { console.log('created(): error getting itemlist: ' + err) });
        })
        .catch((err) => { console.log('created(): error config: ' + err) });
  }
}
</script>

<style scoped>
.title-group {
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  color: #e20613;
  font-family: 'Prompt-medium', 'Arial', sans-serif;
  font-size: 25px;
  font-weight: bold;
  text-align: center;
}
.buttons-container {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
    margin-bottom: 80px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 90%;
  max-width: 600px;
}
.buttons-container .key {
  display:inline-flex;
  flex: 0 0 30%;
  /* padding: 10px; */
  font-family: 'robotobold', 'Courier New', Courier, monospace;
  font-size: 25px;
  font-weight: bold;
  /* width: 33%; */
  height: 60px;
  background-color: white;
  color: black;
  text-align: center;
  vertical-align: middle;
  margin: 5px;
  border-radius: 5px;
}
.buttons-container .key-wide {
  display:inline-flex;
  flex: 0 0 100%;
  /* padding: 10px; */
  font-family: 'Prompt-medium', 'Arial',  sans-serif;
  font-size: 25px;
  font-weight: bold;
  /* width: 33%; */
  height: 60px;
    /*background-color: #dca440;*/
    background-color: #f5dd76;
  color: black;
  /*text-align: center;*/
  vertical-align: middle;
  margin: 5px;
  /*border-radius: 5px;*/
  /*padding-left: 5px;*/
  padding-right: 5px;
}
.buttons-container .key-wide-left {
    display:inline-flex;
    flex: 0 0 100%;
    /* padding: 10px; */
    font-family: 'robotobold', 'Courier New', Courier, monospace;
    font-size: 25px;
    font-weight: bold;
    /* width: 33%; */
    height: 60px;
    background-color: white;
    color: black;
    text-align: left;
    vertical-align: middle;
    margin: 5px;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

.buttons-container .key-wide-left img {
  height: 80%;
  margin-top: auto;
  margin-bottom: auto;
}
.buttons-container .key-wide img {
  height: 100%;
  /*margin-top: auto;*/
  /*margin-bottom: auto;*/
}
.buttons-container .key span {
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 15px;
}
.buttons-container .key-wide div {
    width: 100%;
    height: 100%;
    /*text-align: center;*/
    text-align: left;
    padding-top: 15px;
    padding-left: 10px;
}
.buttons-container .key-wide div span.nr {
    width: 30%;
    height: 100%;
    text-align: center;
    padding-top: 15px;
}
.buttons-container .key-wide-left div {
    width: 100%;
    height: 100%;
    text-align: left;
    padding-top: 15px;
}
.buttons-container .key-wide-left div span.nr {
    width: 30%;
    height: 100%;
    text-align: left;
    padding-top: 15px;
}
.header .sensors {
  position: absolute;
  padding: 5px;
  height: 60px;
  width: 100%;
  top: 0px;
  /* align-items: center; */
}
.header .sensors img {
  height: 60px;
  width: auto;
  margin-right: 30px;
}
.header .flag {
  position: absolute;
  right: 5%;
  top: 5px;
  width: auto;
  height: 60px;
}

</style>
