<template>
  <div id="app">
    <div class="header">
      <div class="home" @click="showHome"><img src="../assets/buttons/header_home.svg" alt="home"></div>
        <div class="sensors">
            <img class="btn-keypad" v-if="keyActive" src="../assets/buttons/header_keypad.svg" alt="Keypad" @click="showKey">
            <img v-if="qrActive" src="/assets/buttons/qr-button-diap.png" alt="QR Scanner" @click="showQR">
            <img v-if="groupsActive" src="/assets/buttons/list-button-diap.png" alt="Group Buttons" @click="showGroups">
        </div>
        <img class="buttonboard" v-if="bbActive" src="../assets/buttons/header_list.svg" alt="Subject Buttons" @click="showBB">
        <div class="header-labels">
            <div class="header-label-home">{{ labelHome }}</div>
            <div class="header-label-list">{{ labelList }}</div>
            <div class="header-label-keypad">{{ labelKeypad }}</div>
        </div>
      </div>
      <div class="object-container">
        <ObjectFiles v-bind:files="welcomeFiles" v-bind:lang="langId" v-bind:browserOs="browserOs" v-bind:browserName="browserName"/>
          <div class="welcome-title">{{ welcomeTitle }}</div>
          <div v-html="welcomeDescription" class="welcome-description"></div>
          <div class="container-welcome-buttons">
<!--              <div class="btn-welcome" @click="showIntro">-->
<!--                  <div class="btn-welcome-icon"><img class="img-welcome-btn" src="../assets/buttons/audio_play.svg"></div>-->
<!--                  <div class="btn-welcome-label">{{ btnStartLabel }}</div>-->
<!--              </div>-->
              <div class="btn-welcome" @click="showInstruction">
                  <div class="btn-welcome-icon"><img class="img-welcome-btn" src="../assets/buttons/audio_play.svg"></div>
                  <div class="btn-welcome-label">{{ btnInstructionLabel }}</div>
              </div>
          </div>
      </div>
      <div class="footer">
        <hr>
        <div class="copy-right">&copy; Easycast Audio Tour</div>
      </div>
  </div>
</template>

<script>
import router from '../router';
import axios from 'axios';
// audio or images
import ObjectFiles from '../components/ObjectFiles';

export default {
  name: 'Welcome',
  components: {
    ObjectFiles
  },
  props: ["langId"],
  data() {
    return {
      browserOs: null,
      browserName: null,
      apiUrl: null,
      apiKey: null,
      apiCollection: null,
      keyActive: false,
      qrActive: false,
      bbActive: false,
      groupsActive: false,
      welcomeData: [],
      welcomeFiles: [],
      welcomeTitle: '',
      welcomeDescription: '',
      btnInstructionLabel: '',
      btnStartLabel: '',
      browseActive: false,
      browseStart: false,
        languageX: '',
        labelHome: '',
        labelKeypad: '',
        labelList: ''
    }
  },
  methods: {
    showHome: function() {
      router.push('/');
    },
    showIntro: function() {
      if(this.browseStart && this.browseActive) {
        // open first subject in audiotour
        axios.get(this.apiUrl + 'items?tags=audio-01&collection=' + this.apiCollection + '&key=' + this.apiKey)
            .then((res) => {
                  let objectId = res.data[0].id;
                  router.push('/object/'+this.langId+'/'+objectId);
                }
            )
            .catch(err => console.log(err));
      } else {
        router.push('/intro/' + this.langId);
      }
    },
    showInstruction: function() {
      router.push('/instruct/' + this.langId);
    },
    showKey: function() {
      router.push('/key/'+this.langId);
    },
    showQR: function() {
      router.push('/qr/'+this.langId);
    },
    showBB: function() {
      router.push('/buttons/'+this.langId);
    },
    showGroups: function() {
      router.push('/groups/'+this.langId);
    },
    showWelcome: function() {
      router.push('/welcome/'+this.langId);
    }
  },
  created() {
    axios.get('/config/app.json')
    .then((res) => {
      let configData = res.data;
      const { detect } = require('detect-browser');
      const browser = detect();
      this.browserOs = browser.os;
      this.browserName = browser.name;
      this.browseActive = configData.browse;
      this.browseStart = configData.browse_start;
      this.groupsActive = configData.groups;
      this.apiUrl = configData.api;
      this.apiKey = configData.api_key;
      this.apiCollection = configData.api_collection;

      const pageLabels = configData.page_welcome[`${this.langId}`];
      const apiUrl = configData.api;
      const apiKey = configData.api_key;
      const apiCollection = configData.api_collection;
      this.keyActive = configData.keypad;
      this.qrActive = configData.qrscanner;
      this.bbActive = configData.buttonboard;
      this.btnInstructionLabel = pageLabels.label_instruction;
      this.btnStartLabel = pageLabels.label_start;
      this.languageX = configData.language_x;
        if(this.langId === 'nl') {
            this.labelHome = configData.header.nl.label_home;
            this.labelList = configData.header.nl.label_list;
            this.labelKeypad = configData.header.nl.label_keypad;
        }
        if(this.langId === 'de') {
            this.labelHome = configData.header.de.label_home;
            this.labelList = configData.header.de.label_list;
            this.labelKeypad = configData.header.de.label_keypad;
        }
        if(this.langId === 'en') {
            this.labelHome = configData.header.en.label_home;
            this.labelList = configData.header.en.label_list;
            this.labelKeypad = configData.header.en.label_keypad;
        }
        if(this.langId === 'fr') {
            this.labelHome = configData.header.fr.label_home;
            this.labelList = configData.header.fr.label_list;
            this.labelKeypad = configData.header.fr.label_keypad;
        }
        if(this.langId === 'x') {
            this.labelHome = configData.header.x.label_home;
            this.labelList = configData.header.x.label_list;
            this.labelKeypad = configData.header.x.label_keypad;
        }
      axios.get(apiUrl + 'items?collection='+apiCollection+'&tags=welcome&key=' + apiKey)
              .then((res) => {
                this.welcomeData = res.data;
                this.welcomeData[0].element_texts.forEach((text_element) => {
                  if(text_element.element.name === 'Titel_' + this.langId){
                    this.welcomeTitle = text_element.text;
                  }
                  if(text_element.element.name === 'Omschrijving_' + this.langId){
                    this.welcomeDescription = text_element.text;
                  }
                });
                // get audiofile(s)
                axios.get(apiUrl + 'files?item='+this.welcomeData[0].id+'&key=' + apiKey)
                        .then((resFiles) => {
                          // this.welcomeFiles = resFiles.data;
                            let filesFromAPI = resFiles.data;
                            filesFromAPI.forEach((fileFound, index) => {
                                if(fileFound.mime_type === "audio/mpeg") {
                                    fileFound.element_texts.forEach((elementText) => {
                                        if(elementText.element.name === 'Title') {
                                            if (elementText.text !== 'audio-' + this.langId) {
                                                filesFromAPI.splice(index,1);
                                            }
                                        }
                                    });
                                }
                            });
                            this.welcomeFiles = filesFromAPI;
                        })
                        .catch((err) => { console.log('error welcomefiles: ' + err) });
              })
              .catch((err) => { console.log('error welcomeData: ' + err) });
    })
    .catch((err) => console.log('error config: ' + err))
  },
  beforeDestroy() {
    this.welcomeFiles=null;
    delete this.welcomeFiles;
  }
}
</script>

<style>
/* .intro {
    width: 100%;
} */
  .intro {
    width: 80%;
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
  }
  .object-container {
    padding-top: 80px;
    width: 100%;
    height: fit-content;
    min-height: 100%;
    padding-bottom: 140px;
    color: black;
  }
  .header .sensors {
    position: absolute;
    padding: 5px;
    height: 70px;
    width: 100%;
    top: 0px;
      text-align: center;
  }
.header .sensors img {
    height: 50px;
    width: auto;
    /*margin-right: 30px;*/
}
.header .sensors img.btn-keypad {
    height: 50px;
    width: auto;
    margin-right: 0;
    margin-top: 16px;
}
.header-labels {
    position: relative;
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
    font-size: 16px;
    /*position: absolute;*/
    top: 80px;
    left: 0;
    width: 100%;
    height: 22px;
    background-color: black;
    color: white;
}
.header-labels .header-label-home {
    position: absolute;
    top: 0;
    left: 4%;
    width: 75px;
    text-align: center;
    /*margin-left: 4%;*/
}
.header-labels .header-label-keypad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
}
.header-labels .header-label-list {
    position: absolute;
    top: 0;
    right: 6%;
    width: 60px;
    text-align: center;
    /*margin-left: 4%;*/
}

.header .buttonboard {
    position: absolute;
    right: 7%;
    top: 22px;
    width: auto;
    height: 50px;
}


.container-buttons {
    width: 50%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
}
.container-welcome-buttons {
    width: 50%;
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
}

.btn-welcome {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 6%;
}

.btn-welcome-icon {
    width: 35%;
}
.img-welcome-btn {
    width: 100%;
}
.btn-welcome-label {
    width: 65%;
    /*margin-left: 10%;*/
    margin-top: -8px;
    font-family: 'Ubuntu', Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-variant-caps: all-small-caps;
    font-size: 1.2em;
}

  .welcome-title {
    font-family: 'Ubuntu', Arial, Helvetica, sans-serif;
    font-size: 2em;
      font-weight: 500;
      text-align: center;
      font-variant-caps: all-small-caps;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
      margin-top: 15%;
  }
  .welcome-description {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
      margin-top: 2%;
      text-align: center;
  }
  .footer {
      position: fixed;
      bottom: 0;
      width: 100%;
      padding-left: 0% !important;
      padding-right: 0% !important;
      color: black;
      text-align: center !important;
      /*background-color: #2e368b !important;*/
      background-color: #eab940;
  }
  .copy-right {
    padding-top: 6px;
    padding-bottom: 6px;
  }

</style>
