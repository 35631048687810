<template>
  <div id="app">
    <div class="header">
      <div class="home"><img src="/assets/buttons/mensinge_home.png" alt="home" @click="showGroups"></div>
        <div class="logo"><img src="/assets/logo_mensinge.png"></div>
    </div>
      <div class="welkom">Welkom</div>
    <div class="buttons-container">
      <div class="key" v-bind:key="item.id" v-for="item in itemList" @click="getGroupObjects(item.groupTag)">
          <div class="body-key">
              <div class="img-key">
                  <img src="/assets/buttons/knop_groep_mensinge.png">
              </div>
              <div class="nr-key">{{ item.nr }}</div>
              <div class="label-key">{{ item.title }}</div>
          </div>
      </div>
    </div>
    <div class="footer">
      <hr>
      <div class="copy-right">&copy; Easycast Audio Tour</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import router from '../router';

export default {
name: "ButtonBoard",
  props: ['langId'],
  data() {
    return {
      apiUrl: null,
      apiKey: null,
      apiCollection: null,
      qrActive: false,
      keyActive: false,
        browseStart: false,
        browseActive: false,
        backToStartActive: false,
      bbWide: false,
      bbNr: false,
        buttonImage: false,
        languageX: '',
      itemList: []
    }
  },
  methods: {
    showHome: function() {
      router.push('/');
    },
    showWelcome: function() {
      router.push('/welcome/' + this.langId);
    },
      showIntro: function() {
          router.push('/intro/' + this.langId);
      },
      showGroups: function() {
          router.push('/groups/' + this.langId);
      },
    getGroupObjects: function(tagIn) {
      router.push('/group/' + tagIn + '/' + this.langId);
    },
    showQR: function() {
      router.push('/qr/'+this.langId);
    },
    showKey: function() {
      router.push('/key/'+this.langId);
    },
    getObject: function(idIn) {
      router.push('/object/'+this.langId+'/'+idIn);
    }
  },
  created() {
    axios.get('/config/app.json')
        .then((res) => {
          let configData = res.data;
          this.qrActive = configData.qrscanner;
          this.keyActive = configData.keypad;
          this.browseActive = configData.browse;
          this.backToStartActive = configData.startbutton;
          this.browseStart = configData.browse_start;
          this.bbWide = configData.bbwide;
          this.bbNr = configData.bbnr;
          this.buttonImage = configData.buttonimage;
          this.apiUrl = configData.api;
          this.apiKey = configData.api_key;
          this.apiCollection = configData.api_collection;
          this.languageX = configData.language_x;
          //210324 - because of introduction new object-type: audio-group query has to include item_type:18 audiotour-item
          axios.get(this.apiUrl + 'items?collection=' + this.apiCollection + '&item_type=19&key=' + this.apiKey)
          .then((itemsResult) => {
            if(itemsResult) {
              itemsResult.data.forEach((item) => {
                // actief
                item.element_texts.forEach((maintext_element) => {
                  if(maintext_element.element.name === 'Actief') {
                    if(maintext_element.text === '1') {
                      // titel
                      item.element_texts.forEach((text_element) => {
                        if (text_element.element.name === 'Titel_' + this.langId) {
                          if(text_element.text !== '') {
                            let itemToPush = {};
                            //tag naam
                            item.element_texts.forEach((elementFound) => {
                              if(elementFound.element.name === 'Tag naam') {
                                  itemToPush.id = item.id;
                                if(elementFound.text !== '') {
                                  itemToPush.groupTag = elementFound.text;
                                  let titleArray = text_element.text.split(':');
                                  if(titleArray && titleArray.length === 2) {
                                      itemToPush.title = titleArray[1];
                                      itemToPush.nr = titleArray[0];
                                  } else {
                                      itemToPush.title = text_element.text;
                                      itemToPush.nr = '?';
                                  }
                                  // console.log('tag for selection: ' + itemToPush.groupTag);
                                  // files
                                    if(item.files.count > 0) {
                                        // get image
                                        axios.get(this.apiUrl + 'files?item=' + item.id + '&key=' + this.apiKey)
                                        .then((filesResult) => {
                                            itemToPush.thumb = filesResult.data[0].file_urls.thumbnail;
                                            // console.log('group image found: ' + itemToPush.thumb);
                                            // console.log(JSON.stringify(itemToPush));
                                            this.itemList.push(itemToPush);
                                            this.itemList.sort((a,b) => {
                                                return a.id - b.id;
                                            });
                                        })
                                        .catch((err) => {
                                            this.itemList.push(itemToPush);
                                            console.log('file request error: ' + err);
                                        });
                                    } else {
                                        this.itemList.push(itemToPush);
                                    }
                                }
                              }
                            });
                            //    einde tag naam
                          }
                        }
                      });
                      //  einde titel

                    }
                  }
                });
                // einde actief
              });
            console.log('itemList: ' + JSON.stringify(this.itemList));
            } else {
              console.log('nothing found!');
            }
          })
          .catch((err) => { console.log('created(): error getting itemlist: ' + err) });
        })
        .catch((err) => { console.log('created(): error config: ' + err) });
  }
}
</script>

<style scoped>
.welkom {
    position: absolute;
    top: 80px;
    width: 100%;
    font-family: Prompt-medium, Arial, sans-serif;
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    color: #e20613;
}
.buttons-container {
  margin-top: 120px;
  margin-left: auto;
  margin-right: auto;
    margin-bottom: 80px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
    /*flex: 0 0 10%;*/
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 90%;
  max-width: 600px;
    overflow: visible;
}
.buttons-container .key {
    /*display:inline-flex;*/
    display:flex;
    flex: 0 0 30%;
  font-family: Prompt, 'Arial', sans-serif;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  /*margin: 5px;*/
    margin-bottom: -90px;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
    /*box-sizing: border-box;*/
    overflow: visible;
    max-width: 30%;
}
.buttons-container .key:before {
    /* The psuedo-element's padding-top percentage is based on the element's width. */
    padding-top: 100%;
    content: '';
    float: left;
}
.buttons-container .key .body-key {
    display: inline-block;
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 100%;
    overflow: visible;
    /*height: fit-content;*/
}
.buttons-container .key .label-key:before {
    /* The psuedo-element's padding-top percentage is based on the element's width. */
    padding-top: 100%;
    content: '';
    float: left;
}
.buttons-container .key .label-key {
    /*display: inline-block;*/
    position: relative;
    font-size: 14px;
    left: 0;
    bottom: 0px;
    width: 100%;
    max-width: 100%;
    overflow: visible;
    /*height: 100%;*/
    height: max-content;
    text-align: center;
    padding-top: 15px;
}
.buttons-container .key .body-key .nr-key:before {
    /* The psuedo-element's padding-top percentage is based on the element's width. */
    padding-top: 100%;
    content: '';
    float: left;
}
.buttons-container .key .body-key .nr-key {
    position: relative;
    /*display: inline-block;*/
    font-size: 48px;
    color: #d0271e;
    margin-top: 20%;
    /*margin-bottom: 30%;*/
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    /*padding-top: 15px;*/
}
.buttons-container .key .body-key .img-key {
    position: absolute;
    /*display: inline-block;*/
    left: 0;
    width: 80%;
    height: 80%;
    margin: 10%;
    height: fit-content;
}
.buttons-container .key .body-key .img-key img {
    object-fit: contain;
    width: 100%;
    height: auto;
}
.buttons-container .key-wide div {
    width: 100%;
    height: 100%;
    text-align: center;
    padding-top: 15px;
}
.header .sensors {
  position: absolute;
  padding: 5px;
  height: 60px;
  width: 100%;
  top: 0px;
  /* align-items: center; */
}
.header .sensors img {
  height: 60px;
  width: auto;
  margin-right: 30px;
}
.header .flag {
  position: absolute;
  right: 5%;
  top: 5px;
  width: auto;
  height: 60px;
}
.footer {
    background-color: #eab940 !important;
}
</style>
