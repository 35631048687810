<template>
  <div id="app">
    <div class="header">
        <div class="home" @click="showHome"><img src="../assets/buttons/header_home.svg" alt="home"></div>
        <div class="sensors">
            <img class="btn-keypad" v-if="keyActive" src="../assets/buttons/header_keypad.svg" alt="Keypad" @click="showKey">
            <img v-if="qrActive" src="/assets/buttons/qr-button-diap.png" alt="QR Scanner" @click="showQR">
            <img v-if="groupsActive" src="/assets/buttons/list-button-diap.png" alt="Group Buttons" @click="showGroups">
        </div>
        <img class="buttonboard" v-if="bbActive" src="../assets/buttons/header_list.svg" alt="Subject Buttons" @click="showBB">
        <div class="header-labels">
            <div class="header-label-home">{{ labelHome }}</div>
            <div class="header-label-list">{{ labelList }}</div>
            <div class="header-label-keypad">{{ labelKeypad }}</div>
        </div>
      </div>
      <div class="object-container">
        <ObjectFiles v-bind:files="introFiles" v-bind:lang="langId" v-bind:browserOs="browserOs" v-bind:browserName="browserName"/>
        <div class="intro-title">{{ introTitle }}</div>
        <div v-html="introDescription" class="intro-description"></div>
      </div>
      <div class="footer">
        <hr>
        <div class="copy-right">&copy; Easycast Audio Tour</div>
      </div>
  </div>
</template>

<script>
import router from '../router';
import axios from 'axios';
import ObjectFiles from '../components/ObjectFiles';

export default {
  name: 'Welcome',
  components: {
    ObjectFiles
  },
  props: ["langId"],
  data() {
    return {
      browserOs: null,
      browserName: null,
      introData: [],
      introFiles: [],
      keyActive: false,
      qrActive: false,
      bbActive: false,
      groupsActive: false,
      introTitle: '',
      introDescription: '',
        languageX: '',
        labelHome: '',
        labelKeypad: '',
        labelList: ''
    }
  },
  methods: {
    showHome: function() {
      router.push('/');
    },
    showKey: function() {
      router.push('/key/'+this.langId);
    },
    showQR: function() {
      router.push('/qr/'+this.langId);
    },
    showBB: function() {
      router.push('/buttons/'+this.langId);
    },
    showGroups: function() {
      router.push('/groups/'+this.langId);
    },
    showWelcome: function() {
      router.push('/welcome/'+this.langId);
    }
  },
  created() {
    axios.get('/config/app.json')
    .then((res) => {
      let configData = res.data;
      const apiUrl = configData.api;
      const apiKey = configData.api_key;
      const apiCollection = configData.api_collection;
      const { detect } = require('detect-browser');
      const browser = detect();
      this.browserOs = browser.os;
      this.browserName = browser.name;
      this.keyActive = configData.keypad;
      this.qrActive = configData.qrscanner;
      this.bbActive = configData.buttonboard;
      this.groupsActive = configData.groups;
      this.languageX = configData.language_x;
        if(this.langId === 'nl') {
            this.labelHome = configData.header.nl.label_home;
            this.labelList = configData.header.nl.label_list;
            this.labelKeypad = configData.header.nl.label_keypad;
        }
        if(this.langId === 'de') {
            this.labelHome = configData.header.de.label_home;
            this.labelList = configData.header.de.label_list;
            this.labelKeypad = configData.header.de.label_keypad;
        }
        if(this.langId === 'en') {
            this.labelHome = configData.header.en.label_home;
            this.labelList = configData.header.en.label_list;
            this.labelKeypad = configData.header.en.label_keypad;
        }
        if(this.langId === 'fr') {
            this.labelHome = configData.header.fr.label_home;
            this.labelList = configData.header.fr.label_list;
            this.labelKeypad = configData.header.fr.label_keypad;
        }
        if(this.langId === 'x') {
            this.labelHome = configData.header.x.label_home;
            this.labelList = configData.header.x.label_list;
            this.labelKeypad = configData.header.x.label_keypad;
        }

        axios.get(apiUrl + 'items?tags=intro&collection=' + apiCollection + '&key=' + apiKey)
              .then((res) => {
                this.introData = res.data;
                // console.log(JSON.stringify(this.introData[0]));
                this.introData[0].element_texts.forEach((text_element) => {
                  if(text_element.element.name === 'Titel_' + this.langId){
                    this.introTitle = text_element.text;
                  }
                  if(text_element.element.name === 'Omschrijving_' + this.langId){
                    this.introDescription = text_element.text;
                  }
                });
                // get audiofile(s)
                axios.get(apiUrl + 'files?item='+this.introData[0].id+'&key=' + apiKey)
                        .then((resFiles) => {
                          // console.log(JSON.stringify(resFiles.data));
                          // this.introFiles = resFiles.data;
                            let filesFromAPI = resFiles.data;
                            filesFromAPI.forEach((fileFound, index) => {
                                if(fileFound.mime_type === "audio/mpeg") {
                                    fileFound.element_texts.forEach((elementText) => {
                                        if(elementText.element.name === 'Title') {
                                            if (elementText.text !== 'audio-' + this.langId) {
                                                filesFromAPI.splice(index,1);
                                            }
                                        }
                                    });
                                }
                            });
                            this.introFiles = filesFromAPI;
                        })
                        .catch();
              })
              .catch();
    })
    .catch((err) => { console.log('config error: ' + err) });
  },
  beforeDestroy() {
    this.introFiles=null;
    delete this.introFiles;
  }
}
</script>

<style scoped>
.intro {
    width: 100%;
}
  .intro {
    width: 80%;
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
  }
  .object-container {
    padding-top: 122px;
    width: 100%;
    height: 100%;
    padding-bottom: 140px;
    color: black;
  }
  .header .intro {
    position: absolute;
    padding: 5px;
    height: 60px;
    top: 0px;
    left: 5%;
  }
  .header .intro img {
    height: 60px;
    width: auto;
  }
  .header .sensors {
    position: absolute;
    padding: 5px;
    height: 60px;
    width: 100%;
    top: 0px;
    /* align-items: center; */
  }
  .header .sensors img {
    height: 60px;
    width: auto;
    margin-right: 30px;
  }
  .header .flag {
    position: absolute;
    right: 5%;
    top: 5px;
    width: auto;
    height: 60px;
  }
  .intro-title {
    font-family: 'Ubuntu', Arial, Helvetica, sans-serif;
      font-weight: 500;
    font-size: 1.2em;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .intro-description {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
    .footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        padding-left: 0% !important;
        padding-right: 0% !important;
        color: black;
        text-align: center;
        background-color: #eab940;
        /*background-color: #2e368b !important;*/
}
  .copy-right {
    padding-top: 6px;
    padding-bottom: 6px;
  }

</style>
