<template>
  <div id="app">
    <div class="header">
        <div class="home" @click="showHome"><img src="../assets/buttons/header_home.svg" alt="home"></div>
      <div class="sensors">
          <img v-if="qrActive" src="/assets/buttons/qr-button-diap.png" alt="QR Scanner" @click="showQR">
          <img v-if="groupsActive" src="/assets/buttons/list-button-diap.png" alt="Group Buttons" @click="showGroups">
      </div>
        <img class="buttonboard" v-if="bbActive" src="../assets/buttons/header_list.svg" alt="Subject Buttons" @click="showBB">
    </div>
      <div class="header-labels">
          <div class="header-label-home">{{ labelHome }}</div>
          <div class="header-label-list">{{ labelList }}</div>
<!--          <div class="header-label-keypad">{{ labelKeypad }}</div>-->
      </div>
    <div class="objectlist-container">
    </div>
    <center>
        <h1 class="audio-code">{{ codeToSend }}</h1>
        <div class="keyboard-container">
            <div class="key" @click="press('1')"><img src="../assets/buttons/key_1.svg"></div>
            <div class="key" @click="press('2')"><img src="../assets/buttons/key_2.svg"></div>
            <div class="key" @click="press('3')"><img src="../assets/buttons/key_3.svg"></div>
            <div class="key" @click="press('4')"><img src="../assets/buttons/key_4.svg"></div>
            <div class="key" @click="press('5')"><img src="../assets/buttons/key_5.svg"></div>
            <div class="key" @click="press('6')"><img src="../assets/buttons/key_6.svg"></div>
            <div class="key" @click="press('7')"><img src="../assets/buttons/key_7.svg"></div>
            <div class="key" @click="press('8')"><img src="../assets/buttons/key_8.svg"></div>
            <div class="key" @click="press('9')"><img src="../assets/buttons/key_9.svg"></div>
            <div class="key" @click="press('-')"><img src="../assets/buttons/key_back.svg"></div>
            <div class="key" @click="press('0')"><img src="../assets/buttons/key_0.svg"></div>
            <div class="key" @click="press('+')"><img src="../assets/buttons/key_go.svg"></div>
            <div class="key"><div class="label_back">{{ labelBack }}</div></div>
            <div class="key"></div>
            <div class="key"><div class="label_go">{{ labelGo }}</div></div>
        </div>
    </center>
    <div class="footer">
      <hr>
      <div class="copy-right">&copy; Easycast Audio Tour</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import router from '../router';
let codeToSend = '00';
export default {
  name: 'KeyPad',
  components: {
  },
  props: ['langId'],
  data() {
    return {
        codeToSend,
        apiUrl: null,
        apiKey: null,
        apiCollection: null,
        qrActive: false,
        bbActive: false,
        groupsActive: false,
        languageX: '',
        labelHome: '',
        labelKeypad: '',
        labelList: '',
        labelBack: '',
        labelGo: ''
    }
  },
  methods: {
    showHome: function() {
      router.push('/');
    },
    showWelcome: function() {
      router.push('/welcome/' + this.langId);
    },
    showIntro: function() {
      router.push('/intro/' + this.langId);
    },
    showQR: function() {
      router.push('/qr/'+this.langId);
    },
    showBB: function() {
      router.push('/buttons/'+this.langId);
    },
    showGroups: function() {
      router.push('/groups/'+this.langId);
    },
    handleCode(codeIn) {
        const codeSearch = 'audio-' + codeIn;
        // console.log('axios query: ' + this.apiUrl + 'items?tags='+codeSearch+'&collection=' + this.apiCollection + '&key=' + this.apiKey);
        axios.get(this.apiUrl + 'items?tags='+codeSearch+'&collection=' + this.apiCollection + '&key=' + this.apiKey)
          .then((res) => {
            var objectId = res.data[0].id;
            router.push('/object/'+this.langId+'/'+objectId);
            }
          )
          .catch(err => console.log(err));
    },
    press(keyIn) {
        // console.log(keyIn);
        if(keyIn === '+') {
            // if(this.codeToSend !== '00' && this.codeToSend.length === 2) {
            //     this.handleCode(this.codeToSend);
            // }
            if(this.codeToSend !== '00') {
                if(this.codeToSend.length === 1) {
                    this.codeToSend = '0' + this.codeToSend;
                }
                this.handleCode(this.codeToSend);
            }
        }
        else if(keyIn === '-') {
            if(this.codeToSend.length <= 1) {
                this.codeToSend = '00';
            } else {
                this.codeToSend = this.codeToSend.slice(0,-1);
            }
        } else {
            if(this.codeToSend === '00') {
                this.codeToSend = keyIn;
            } else {
                if(this.codeToSend.length < 2) {
                this.codeToSend = this.codeToSend + keyIn;
                }
            }
        }
    }
  },
  created() {
      axios.get('/config/app.json')
      .then((res) => {
        let configData = res.data;
        this.qrActive = configData.qrscanner;
        this.bbActive = configData.buttonboard;
        this.groupsActive = configData.groups;
        this.apiUrl = configData.api;
        this.apiKey = configData.api_key;
        this.apiCollection = configData.api_collection;
        this.languageX = configData.language_x;
        if(this.langId === 'nl') {
            this.labelBack = configData.page_keypad.nl.label_back;
            this.labelGo = configData.page_keypad.nl.label_go;
            this.labelHome = configData.header.nl.label_home;
            this.labelList = configData.header.nl.label_list;
            this.labelKeypad = configData.header.nl.label_keypad;
        }
          if(this.langId === 'de') {
              this.labelBack = configData.page_keypad.de.label_back;
              this.labelGo = configData.page_keypad.de.label_go;
              this.labelHome = configData.header.de.label_home;
              this.labelList = configData.header.de.label_list;
              this.labelKeypad = configData.header.de.label_keypad;
          }
          if(this.langId === 'en') {
              this.labelBack = configData.page_keypad.en.label_back;
              this.labelGo = configData.page_keypad.en.label_go;
              this.labelHome = configData.header.en.label_home;
              this.labelList = configData.header.en.label_list;
              this.labelKeypad = configData.header.en.label_keypad;
          }
          if(this.langId === 'fr') {
              this.labelBack = configData.page_keypad.fr.label_back;
              this.labelGo = configData.page_keypad.fr.label_go;
              this.labelHome = configData.header.fr.label_home;
              this.labelList = configData.header.fr.label_list;
              this.labelKeypad = configData.header.fr.label_keypad;
          }
          if(this.langId === 'x') {
              this.labelBack = configData.page_keypad.x.label_back;
              this.labelGo = configData.page_keypad.x.label_go;
              this.labelHome = configData.header.x.label_home;
              this.labelList = configData.header.x.label_list;
              this.labelKeypad = configData.header.x.label_keypad;
          }
      })
      .catch((err) => { console.log('error config: ' + err) });
  }
}
</script>

<style>
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  body {
    font-family: 'Ubuntu', Arial, Helvetica, sans-serif;
      font-weight: 500;
      line-height: 1.4;
  }
  .header .sensors {
      position: absolute;
      padding: 5px;
      height: 70px;
      width: 100%;
      top: 0px;
      text-align: center;
  }
  .header .sensors img {
      height: 50px;
      width: auto;
      /*margin-right: 30px;*/
  }
  .header .sensors img.btn-keypad {
      height: 50px;
      width: auto;
      margin-right: 0;
      margin-top: 16px;
  }
  .header .buttonboard {
      position: absolute;
      right: 3%;
      top: 10px;
      width: auto;
      height: 70px;
  }
  .header-labels {
      position: relative;
      font-size: 16px;
      /*position: absolute;*/
      top: 80px;
      left: 0;
      width: 100%;
      height: 22px;
      background-color: black;
      color: white;
  }
  .header-labels .header-label-home {
      position: absolute;
      top: 0;
      left: 4%;
      width: 75px;
      text-align: center;
      /*margin-left: 4%;*/
  }
  .header-labels .header-label-keypad {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      text-align: center;
  }
  .header-labels .header-label-list {
      position: absolute;
      top: 0;
      right: 6%;
      width: 60px;
      text-align: center;
      /*margin-left: 4%;*/
  }
.keyboard-container {
    display: flex;
    flex-wrap: wrap;
    /*flex-direction: row;*/
    justify-content: center;
    /*align-items: center;*/
    /*text-align: center;*/
    width: 70%;
    max-width: 600px;
    height: fit-content;
}
.keyboard-container .key {
    display:inline-flex;
    width: 28%;
     padding: 1%;
    font-family: 'Ubuntu', 'Courier New', Courier, monospace;
    font-size: 25px;
    font-weight: 700;
    color: black;
    text-align: center;
    vertical-align: middle;
    margin: 5px;
}

  .keyboard-container .key img {
      width: 100%;
      height: 100%;
  }
  .keyboard-container .key .label_back, .keyboard-container .key .label_go {
      width: 100%;
      text-align: center;
      font-family: "Ubuntu", sans-serif;
      font-weight: 500;
      font-size: 0.7em;
      margin-top: -10px;
  }

  .objectlist-container {
    position: absolute;
    top: 120px;
    color: white;
  }
  .audio-code {
          margin-top: 118px;
          margin-bottom: 10px;
          color: black;
      font-family: 'Ubuntu',sans-serif;
      font-weight: 500;
      font-size: 3em;
  }
  .footer {
      position: fixed;
      bottom: 0;
      width: 100%;
      padding-left: 0% !important;
      padding-right: 0% !important;
      color: black;
      text-align: center;
      background-color: #eab940;
  }
  .copy-right {
    padding-top: 6px;
    padding-bottom: 6px;
  }
</style>
