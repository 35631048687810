<template>
  <div id="app">
      <div class="header">
          <div class="home" @click="showHome"><img src="../assets/buttons/header_home.svg" alt="home"></div>
          <div class="sensors">
              <img class="btn-keypad" v-if="keyActive" src="../assets/buttons/header_keypad.svg" alt="Keypad" @click="showKey">
              <img v-if="qrActive" src="/assets/buttons/qr-button-diap.png" alt="QR Scanner" @click="showQR">
              <img v-if="groupsActive" src="/assets/buttons/list-button-diap.png" alt="Group Buttons" @click="showGroups">
          </div>
          <img class="buttonboard" v-if="bbActive" src="../assets/buttons/header_list.svg" alt="Subject Buttons" @click="showBB">
          <div class="header-labels">
              <div class="header-label-home">{{ labelHome }}</div>
              <div class="header-label-list">{{ labelList }}</div>
              <div class="header-label-keypad">{{ labelKeypad }}</div>
          </div>
      </div>
    <div class="object-container">
      <ObjectFiles v-bind:files="instructFiles" v-bind:lang="langId" v-bind:browserOs="browserOs" v-bind:browserName="browserName"/>
      <div class="instruct-title">{{ instructTitle }}</div>
      <div v-html="instructDescription" class="instruct-description"></div>
<!--      <button class="btn-start" @click="showIntro">{{ btnStartLabel }}</button>-->
        <div class="btn-instruct-start" @click="showKey">
            <div class="btn-instruct-start-icon"><img class="img-welcome-btn" src="../assets/buttons/audio_play.svg"></div>
            <div class="btn-instruct-start-label">{{ btnStartLabel }}</div>
        </div>
        <div class="instruct-sponsor">
            <div class="instruct-sponsor-logo">
                <img src="../assets/logo-kickstart.png">
            </div>
            <div class="instruct-sponsor-label">Mogelijk gemaakt door het Kickstartcultuurfonds</div>
        </div>
    </div>
    <div class="footer">
      <hr>
      <div class="copy-right">&copy; Easycast Audio Tour</div>
    </div>
  </div>
</template>

<script>
  import router from '../router';
  import axios from 'axios';
  // import configData from '../../public/config/app.json';
  // probeersel
  import ObjectFiles from '../components/ObjectFiles';

  export default {
    name: 'Instruct',
    components: {
      ObjectFiles
    },
    props: ["langId"],
    data() {
      return {
        qrActive: false,
        bbActive: false,
        keyActive: false,
        browserOs: null,
        browserName: null,
        apiUrl: null,
        apiKey: null,
        apiCollection: null,
        instructData: [],
        instructFiles: [],
        instructTitle: '',
        instructDescription: '',
        btnInstructionLabel: '',
        btnStartLabel: '',
        browseActive: false,
        browseStart: false,
        groupsActive: false,
          languageX: '',
          labelHome: '',
          labelKeypad: '',
          labelList: ''
      }
    },
    methods: {
      showHome: function() {
        router.push('/');
      },
      showIntro: function() {
        if(this.browseStart && this.browseActive) {
          // open first subject in audiotour
          axios.get(this.apiUrl + 'items?tags=audio-01&collection=' + this.apiCollection + '&key=' + this.apiKey)
              .then((res) => {
                    let objectId = res.data[0].id;
                    router.push('/object/'+this.langId+'/'+objectId);
                  }
              )
              .catch(err => console.log(err));
        } else {
          router.push('/intro/' + this.langId);
        }
        // router.push('/intro/' + this.langId);
      },
      showInstruction: function() {
        router.push('/instruct/' + this.langId);
      },
      showKey: function() {
        router.push('/key/'+this.langId);
      },
      showQR: function() {
        router.push('/qr/'+this.langId);
      },
      showBB: function() {
        router.push('/buttons/'+this.langId);
      },
      showGroups: function() {
        router.push('/groups/'+this.langId);
      },
      showWelcome: function() {
        router.push('/welcome/'+this.langId);
      }
    },
    created() {
      axios.get('/config/app.json')
              .then((res) => {
                let configData = res.data;
                // const apiUrl = configData.api;
                // const apiKey = configData.api_key;
                // const apiCollection = configData.api_collection;
                const { detect } = require('detect-browser');
                const browser = detect();
                this.browserOs = browser.os;
                this.browserName = browser.name;
                this.browseActive = configData.browse;
                this.browseStart = configData.browse_start;
                // this.objectId = 0
                this.keyActive = configData.keypad;
                this.qrActive = configData.qrscanner;
                this.bbActive = configData.buttonboard;
                this.groupsActive = configData.groups;
                this.apiUrl = configData.api;
                this.apiKey = configData.api_key;
                this.apiCollection = configData.api_collection;
                const pageLabels = configData.page_instruction[`${this.langId}`];
                this.btnStartLabel = pageLabels.label_start;
                  this.languageX = configData.language_x;
                  if(this.langId === 'nl') {
                      this.labelHome = configData.header.nl.label_home;
                      this.labelList = configData.header.nl.label_list;
                      this.labelKeypad = configData.header.nl.label_keypad;
                  }
                  if(this.langId === 'de') {
                      this.labelHome = configData.header.de.label_home;
                      this.labelList = configData.header.de.label_list;
                      this.labelKeypad = configData.header.de.label_keypad;
                  }
                  if(this.langId === 'en') {
                      this.labelHome = configData.header.en.label_home;
                      this.labelList = configData.header.en.label_list;
                      this.labelKeypad = configData.header.en.label_keypad;
                  }
                  if(this.langId === 'fr') {
                      this.labelHome = configData.header.fr.label_home;
                      this.labelList = configData.header.fr.label_list;
                      this.labelKeypad = configData.header.fr.label_keypad;
                  }
                  if(this.langId === 'x') {
                      this.labelHome = configData.header.x.label_home;
                      this.labelList = configData.header.x.label_list;
                      this.labelKeypad = configData.header.x.label_keypad;
                  }

                  axios.get(this.apiUrl + 'items?tags=instruct&collection=' + this.apiCollection + '&key=' + this.apiKey)
                        .then((res) => {
                          this.instructData = res.data;
                          this.instructData[0].element_texts.forEach((text_element) => {
                            if(text_element.element.name === 'Titel_' + this.langId){
                              this.instructTitle = text_element.text;
                            }
                            if(text_element.element.name === 'Omschrijving_' + this.langId){
                              this.instructDescription = text_element.text;
                            }
                          });
                          // get audiofile(s)
                          axios.get(this.apiUrl + 'files?item='+this.instructData[0].id+'&key=' + this.apiKey)
                                  .then((resFiles) => {
                                    // this.instructFiles = resFiles.data;
                                      let filesFromAPI = resFiles.data;
                                      filesFromAPI.forEach((fileFound, index) => {
                                          if(fileFound.mime_type === "audio/mpeg") {
                                              fileFound.element_texts.forEach((elementText) => {
                                                  if(elementText.element.name === 'Title') {
                                                      if (elementText.text !== 'audio-' + this.langId) {
                                                          filesFromAPI.splice(index,1);
                                                      }
                                                  }
                                              });
                                          }
                                      });
                                      this.instructFiles = filesFromAPI;
                                  })
                                  .catch();
                        })
                        .catch();
              })
              .catch((err) => { console.log('config error: ' + err) });
    },
    beforeDestroy() {
      this.instructFiles=null;
      delete this.instructFiles;
    }
  }
</script>

<style scoped>
  /* .intro {
      width: 100%;
  } */
  .instruct {
    width: 80%;
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
  }
  .object-container {
    padding-top: 122px;
    padding-bottom: 140px;
    min-height: 100%;
    padding-bottom: 140px;
    color: black;
  }
  .instruct-title {
    font-family: 'Ubuntu', Arial, Helvetica, sans-serif;
    font-size: 1.3em;
      font-weight: 700;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .instruct-description {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .instruct-sponsor {
      display: flex;
      align-items: center;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
  }
  .instruct-sponsor-logo {
      width: 15%;
  }
  .instruct-sponsor-logo img {
      width: 100%;
      height: auto;
  }
  .instruct-sponsor-label {
      margin-left: 2%;
      margin-top: -2%;
      /*text-align: right;*/
      width: 83%;
      font-family: "Ubuntu", sans-serif;
      font-weight: 700;
      font-size: 0.7em;
  }
  .header .sensors {
    position: absolute;
    padding: 5px;
    height: 60px;
    width: 100%;
    top: 0px;
    /* align-items: center; */
  }
  .header .sensors img {
    height: 60px;
    width: auto;
    margin-right: 30px;
  }
  .header .flag {
    position: absolute;
    right: 5%;
    top: 5px;
    width: auto;
    height: 60px;
  }
  .btn-instruction {
    font-family: 'robotoregular', Arial, Helvetica, sans-serif;
    font-size: 1.2em;
    width: 100%;
    margin-top: 5%;
  }
  .btn-start {
    font-family: 'Ubuntu Medium', Arial, Helvetica, sans-serif;
    font-size: 1.2em;
    width: 90%;
    margin-top: 5%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .btn-instruct-start {
      width: 70%;
      margin-right: auto;
      margin-left: auto;
      display: flex;
      align-items: center;
      margin-bottom: 6%;
  }

  .btn-instruct-start-icon {
      width: 35%;
  }
  .img-instruct-start-btn {
      width: 100%;
  }
  .btn-instruct-start-label {
      width: 65%;
      /*margin-left: 10%;*/
      margin-top: -8px;
      font-family: 'Ubuntu', Helvetica, Arial, sans-serif;
      /*font-variant-caps: all-small-caps;*/
      font-size: 1.2em;
      font-weight: 700;
  }

  .footer {
      position: fixed;
      bottom: 0;
      width: 100%;
      padding-left: 0% !important;
      padding-right: 0% !important;
      color: black;
      text-align: center;
      background-color: #eab940;
      /*background-color: #2e368b !important;*/
  }
  .copy-right {
    padding-top: 6px;
    padding-bottom: 6px;
  }

</style>
