<template>
    <div>
        <div class="image-container" v-if="file.mime_type === 'image/jpeg' || file.mime_type === 'image/png'">
<!--            <img class = "audio-image"  v-bind:src="file.file_urls.original" width="100%" height="auto" >-->
            <img class = "audio-image"  v-bind:src="file.file_urls.original">
        </div>
        <div class="audio-container" v-if="file.mime_type === 'audio/mpeg' && audioLabel === ('audio-' + lang)">
            <audio v-if="browserName !== 'ios' && browserOs !== 'iOS'" v-bind:autoplay="isAutoPlay" @timeupdate="updateProgress" @ended="audioEnded" @playing="audioPlaying">
                <source  v-bind:src="file.file_urls.original" type="audio/mpeg">
            </audio>
            <audio v-else @timeupdate="updateProgress" @ended="audioEnded">
                <source  v-bind:src="file.file_urls.original" type="audio/mpeg">
            </audio>
            <div class="control-container">
                <div class="progress-container">
                    <div class="progress-indicator" v-bind:style="{width: parseInt((audioProgress / audioDuration) * 100) + '%'}"></div>
                </div>
                <div v-if="bkwSkipable === true" class="button-skipbackward" @click="skipAudioBackward"><img src="../assets/buttons/audio_back.svg"></div>
                <div v-if="bkwSkipable === true" class="label-skipbackward">{{ labelAudioBackward }}</div>
                <div v-if="isPlaying === false" class="button-play-pause" @click="playAudio"><img src="../assets/buttons/audio_play.svg"></div>
                <div v-if="isPlaying === false" class="label-play-pause">{{ labelAudioPlay }}</div>
                <div v-if="isPlaying === true" class="button-play-pause" @click="pauseAudio"><img src="../assets/buttons/audio_pause.svg"></div>
                <div v-if="isPlaying === true" class="label-play-pause">{{ labelAudioPause }}</div>
                <div v-if="fwdSkipable === true" class="button-skipforward" @click="skipAudioForward"><img src="../assets/buttons/audio_forward.svg"></div>
                <div v-if="fwdSkipable === true" class="label-skipforward">{{ labelAudioForward }}</div>
<!--                <div class="duration-label" id="durationlabel">{{ audioDurationLabel }}</div>-->
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    export default {
    name: "ObjectFileItem",
    props: ["file", "lang"],
    data() {
        return {
            browserOs: null,
            browserName: null,
            audioProgress: 0,
            audioDuration: 1,
            audio: null,
            audioFile: '',
            audioLabel: '',
            audioDurationLabel: '',
            audioDurationNumber: 0,
            isPlaying: false,
            fwdSkipable: false,
            bkwSkipable: false,
            isAutoPlay: false,
            labelAudioBackward: '',
            labelAudioPlay: '',
            labelAudioPause: '',
            labelAudioForward: ''
        }
    },
    methods: {
        playAudio: function() {
            // console.log('playAudio');
            let player = document.querySelector('audio');
            if(player !== null) {
                this.isPlaying = true;
                player.play();
                // this.audioDurationLabel = this.setDurationLabel(player.duration);
            } else {
                console.log('audio element is null!');
            }
        },
        pauseAudio() {
            // if(this.audio !== null) {
            //     this.isPlaying = false;
            //     this.audio.pause();
            // }
            let player = document.querySelector('audio');
            if(player !== null) {
                this.isPlaying = false;
                player.pause();
            }
        },
        skipAudioForward() {
            // if(this.audio !== null) {
            //     this.audio.currentTime = this.audio.currentTime + 5;
            // }
            let player = document.querySelector('audio');
            if(player !== null) {
                player.currentTime = player.currentTime + 5;
            }
        },
        skipAudioBackward() {
            // if(this.audio !== null) {
            //     if (this.audio.currentTime > 5) {
            //         this.audio.currentTime = this.audio.currentTime - 5;
            //     } else {
            //         this.audio.currentTime = 0;
            //     }
            // }
            let player = document.querySelector('audio');
            if(player !== null) {
                if (player.currentTime > 5) {
                    player.currentTime = player.currentTime - 5;
                } else {
                    player.currentTime = 0;
                }
            }
        },
        updateProgress() {
            // this.audioProgress = this.audio.currentTime;
            // this.audioDuration = this.audio.duration;
            // this.bkwSkipable = true;
            // if(this.audio.duration - this.audio.currentTime > 5) {
            //     this.fwdSkipable = true;
            // } else {
            //     this.fwdSkipable = false;
            // }
            let player = document.querySelector('audio');
            this.audioProgress = player.currentTime;
            this.audioDuration = player.duration;
            this.bkwSkipable = true;
            if(player.duration - player.currentTime > 5) {
                this.fwdSkipable = true;
            } else {
                this.fwdSkipable = false;
            }
        },
        audioEnded() {
            this.isPlaying = false;
            this.bkwSkipable = false;
        },
        audioPlaying() {
            this.isPlaying = true;
        },
    },
    created() {
        axios.get('/config/app.json')
            .then((res) => {
                let appConfig = res.data;
                const { detect } = require('detect-browser');
                const browser = detect();
                this.browserOs = browser.os;
                this.browserName = browser.name;
                this.isAutoPlay = appConfig.autoplay;
                var audioPathSplit = this.file.original_filename.split('/');
                this.audioFile = audioPathSplit[audioPathSplit.length - 1];
                // title, text and tags
                if(this.lang === 'nl') {
                    this.labelAudioBackward = appConfig.audioplayer.nl.label_backward;
                    this.labelAudioPlay = appConfig.audioplayer.nl.label_play;
                    this.labelAudioPause = appConfig.audioplayer.nl.label_pause;
                    this.labelAudioForward = appConfig.audioplayer.nl.label_forward;
                }
                if(this.lang === 'de') {
                    this.labelAudioBackward = appConfig.audioplayer.de.label_backward;
                    this.labelAudioPlay = appConfig.audioplayer.de.label_play;
                    this.labelAudioPause = appConfig.audioplayer.de.label_pause;
                    this.labelAudioForward = appConfig.audioplayer.de.label_forward;
                }
                if(this.lang === 'en') {
                    this.labelAudioBackward = appConfig.audioplayer.en.label_backward;
                    this.labelAudioPlay = appConfig.audioplayer.en.label_play;
                    this.labelAudioPause = appConfig.audioplayer.en.label_pause;
                    this.labelAudioForward = appConfig.audioplayer.en.label_forward;
                }
                if(this.lang === 'fr') {
                    this.labelAudioBackward = appConfig.audioplayer.fr.label_backward;
                    this.labelAudioPlay = appConfig.audioplayer.fr.label_play;
                    this.labelAudioPause = appConfig.audioplayer.fr.label_pause;
                    this.labelAudioForward = appConfig.audioplayer.fr.label_forward;
                }
                if(this.lang === 'x') {
                    this.labelAudioBackward = appConfig.audioplayer.x.label_backward;
                    this.labelAudioPlay = appConfig.audioplayer.x.label_play;
                    this.labelAudioPause = appConfig.audioplayer.x.label_pause;
                    this.labelAudioForward = appConfig.audioplayer.x.label_forward;
                }

                if(this.file.element_texts[0]) {
                    this.audioLabel = this.file.element_texts[0].text;
                }
                // console.log('file:' + this.audioFile);
                if(this.file.mime_type === 'audio/mpeg') {
                    setTimeout(function() {
                        console.log('uitgestelde taak create')
                        this.audio = document.querySelector('audio');
                        if(this.audio !== null) {
                            console.log('audio element gevonden:'+ this.audio.toString());
                            console.log('audio file' + audioPathSplit[audioPathSplit.length - 1] + 'duration:' + this.audio.duration);
                            // this.audioDurationNumber =  this.setDurationLabel(this.audio.duration);
                            // omzetten
                            let durationRaw = this.audio.duration;
                            let durationMinutes = '00';
                            let durationSeconds = '00';
                            if (durationRaw > 59) {
                                let durationSecondsNumber = durationRaw%60;
                                if (durationSecondsNumber < 10) {
                                    durationSeconds = '0' + durationSecondsNumber.toFixed(0);
                                } else {
                                    durationSeconds = durationSecondsNumber.toFixed(0).toString();
                                }
                                let durationMinutesNumber = (durationRaw/60).toFixed(0);
                                if (durationMinutesNumber < 10) {
                                    durationMinutes = '0' + durationMinutesNumber.toString();
                                } else {
                                    durationMinutes = durationMinutesNumber.toString();
                                }
                            } else {
                                if (durationRaw < 10) {
                                    durationSeconds = '0' + durationRaw.toFixed(0).toString();
                                } else {
                                    durationSeconds = durationRaw.toFixed(0).toString();
                                }
                            }
                            this.audioDurationLabel = durationMinutes + ':' + durationSeconds;
                            // console.log(this.audioDurationLabel);
                            // Hans: geen label: document.getElementById("durationlabel").innerText = this.audioDurationLabel;

                            // omzetten

                            this.audio.onloadeddata = () => {
                                // document.getElementById("durationlabel").innerText = this.setDurationLabel(this.audio.duration);
                                // this.audioDurationLabel = this.setDurationLabel(this.audio.duration);
                                // console.log('audio duration: ' + this.audio.duration);
                            };
                            this.audio.onloadedmetadata = () => {
                                console.log('onloadedmetadata: ' + this.audio.duration);
                                // document.getElementById("durationlabel").innerText = this.setDurationLabel(this.audio.duration);
                            };
                        } else {
                            console.log('geen audio elementen gevonden')
                        }
                    }, 1000)

                }

            })
            .catch((err) => { console.log('Object fileItem config error: ' + err) });
    },
    beforeDestroy() {
        if(this.audio !== null) {
        this.audio.pause();
        console.log('audio geforceerd gestopt!');
        }
    },
    mounted() {
        // this.audio = document.querySelector('audio');
        // if(this.audio !== null) {
        //     console.log('audio element gevonden:'+this.audio);
        //     console.log('browser: ' + this.browserName + ' - ' + this.browserOs);
        // } else {
        //     console.log('geen audio elementen gevonden')
        // }
    }
}
</script>

<style scoped>
    .file-item {
        background: #ce9d4a;
        /* padding: 10px; */
        border-bottom: 1px #ccc dotted;
    }

    audio {
        width: 100%;
    }

    .audio-container {
        position: fixed;
        bottom: 35px;
        width: 100%;
        /*background-color: #2e368bcc;*/
    }

    .audio-image {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .image-container {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        /* margin-top: 80px; */
         height: 250px;
        overflow: hidden;
    }
    .control-container {
        position: relative;
        width:100%;
        max-width: 600px;
        height: 100px;
        margin-left: auto;
        margin-right: auto;
        /*padding: 5px;*/
        background-color: #ce9d4aee;
    }
    .button-skipforward {
        position: absolute;
        top: 10px;
        right: 20%;
        width: 45px;
        height: 65px;
    }
    .button-skipforward img {
        object-fit: fill;
        width: 100%;
        height: 100%;
    }
    .label-skipforward {
        position: absolute;
        top: 75px;
        right: 11%;
        width: 30%;
        text-align: center;
        font-family: "Ubuntu", sans-serif;
        font-weight: 500;
        font-size: 0.8em;
        color: black;
    }
    .button-skipbackward {
        position: absolute;
        top: 10px;
        left: 20%;
        width: 45px;
        height: 65px;
    }
    .button-skipbackward img {
        object-fit: fill;
        width: 100%;
        height: 100%;
        /*max-width: 100%;*/
    }
    .label-skipbackward {
        position: absolute;
        top: 75px;
        left: 11%;
        width: 30%;
        text-align: center;
        font-family: "Ubuntu", sans-serif;
        font-weight: 500;
        font-size: 0.8em;
        color: black;
    }
    .button-play-pause {
        width: 100px;
        margin-left: auto;
        margin-right: auto;
    }
    .button-play-pause img {
        object-fit: contain;
        max-width: 100%;
        margin-top: -31px;
    }
    .label-play-pause {
        position: absolute;
        top: 75px;
        /*left: 10%;*/
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        font-family: "Ubuntu", sans-serif;
        font-weight: 500;
        font-size: 0.8em;
        color: black;
    }
    .progress-container {
        /*background-color: gray;*/
        width: 100%;
        height: 7px;
        margin-top: 0;
        margin-bottom: 20px;
    }
    .progress-indicator {
        background-color: white;
        height: 100%;
    }
    .duration-label {
        position: absolute;
        top: 10px;
        right: 10px;
        font-family: "Ubuntu",sans-serif ;
        font-weight: 500;
        font-size: 1em;
        color: black;
    }
</style>
